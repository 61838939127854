.setting-main-content {
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
}

.main-setting-container {
    padding: 30px 15px;
    background-color: transparent;
    color: var(--text-color);
}

.main-setting-header h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.main-setting-tabs {
    display: flex;
    gap: 24px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 24px;
    overflow-x: auto;
}

.main-setting-tab-button {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray-dark);
    border: none;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.2s, border-color 0.2s;
}

.main-setting-tab-button:hover {
    color: var(--text-color);
}

.main-setting-tab-button.active {
    color: var(--text-color);
    border-bottom: 2px solid var(--primary-color);
}

.setting-personal-detail-form-wrapper {
    padding: 20px;
    margin: auto;
    font-family: var(--font-family-primary);
}

.three-col-layout {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.detail-left {
    flex: 1;
}

.detail-middle {
    flex: 2.5;
}

.detail-right {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setting-personal-detail-section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-personal-detail-section-subtext {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.setting-personal-detail-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.full-span {
    grid-column: span 2;
}

.setting-personal-detail-label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--gray-light);
}

.setting-personal-detail-input,
.setting-personal-detail-select {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid var(--active-tab);
    border-radius: var(--border-radius);
    font-size: 14px;
    outline: none;
    transition: border 0.2s ease;
    background: transparent;
    color: var(--text-color);
}

.setting-phone,
.gender-select {
    color: var(--gray-dark);
}

.react-datepicker-wrapper {
    width: 100%;
    padding: 10px;
}

.setting-personal-detail-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 20px 10px 10px;
    background-image: url("data:image/svg+xml,%3Csvg fill='gray' height='40' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 20px;
    color: var(--gray-dark);
}

.setting-personal-detail-input::placeholder {
    color: var(--gray-dark);
}

.setting-personal-detail-select.country-code option {
    vertical-align: middle;
}

.setting-personal-detail-input:focus,
.setting-personal-detail-select:focus {
    border-color: var(--text-color);
    color: var(--text-color);
}

.setting-personal-detail-avatar-wrapper {
    position: relative;
    margin-bottom: 10px;
}

.setting-personal-detail-avatar-img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--gray-light);
}

.setting-personal-detail-avatar-loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
}

.setting-personal-detail-photo-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.setting-personal-detail-button {
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid var(--active-tab);
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.2s ease;
    color: var(--text-color);
}

.setting-personal-detail-button:hover {
    border-color: var(--text-color);
}

.setting-personal-detail-delete-btn {
    padding: 8px;
    background-color: transparent;
    color: var(--gray-dark);
    border: 1px solid var(--active-tab);
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: background 0.2s ease;
}

.setting-personal-detail-delete-btn:hover {
    border-color: var(--text-color);
}

.setting-personal-detail-phone-wrapper {
    display: flex;
    gap: 10px;
}

.country-code {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-personal-detail-hint {
    margin-top: 8px;
    font-size: 14px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.setting-personal-detail-divider {
    border: none;
    border-top: 1px solid var(--border-color);
    margin: 35px 0;
}

.setting-personal-detail-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
}

.setting-personal-detail-button.cancel {
    background-color: transparent;
    border: 1px solid var(--active-tab);
    color: var(--gray-dark);
    border-radius: var(--border-radius);
    font-size: 16px;
    padding: 6px 26px;
}

.setting-personal-detail-button.cancel:hover {
    border-color: var(--text-color);
}

.setting-personal-detail-button.save {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    border-radius: var(--border-radius);
    font-size: 16px;
    padding: 6px 26px;
}

.setting-device-container {
    padding: 0 20%;
    background-color: transparent;
}

.setting-device-upload-icon-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--primary-light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.setting-device-upload-icon {
    font-size: 50px;
    color: var(--primary-color);
}

.setting-device-heading {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-device-description {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin-bottom: 20px;
}

.setting-device-current-session {
    background-color: var(--gray-background);
    border-radius: var(--border-radius);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 15px;
}

.setting-device-session-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.setting-device-session-icon {
    color: var(--gray-light);
    font-size: 20px;
}

.setting-device-session-details {
    flex: 1;
}

.setting-device-session-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-device-session-subtext {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.setting-device-session-status {
    color: var(--green-text);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.setting-device-divider {
    border: none;
    border-top: 1px solid var(--gray-dark);
    margin: 0;
}

.setting-device-terminate-button {
    display: block;
    color: var(--red-color);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    cursor: pointer;
    transition: color 0.2s ease;
}

.setting-device-recent-heading {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin: 15px 0;
}

.setting-device-recent-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.setting-device-recent-item {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 8px 0;
}

.setting-device-recent-icon {
    font-size: 20px;
    margin-right: 15px;
    color: var(--gray-light);
}

.setting-device-recent-info {
    flex: 1;
}

.setting-device-recent-title {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-device-recent-subtext {
    font-size: 12px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.setting-device-recent-time {
    font-size: 12px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    white-space: nowrap;
}

.setting-notification-container {
    padding: 0 20px;
    background-color: transparent;
    max-width: 50%;
}

.setting-notification-subtitle {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0 20px 0;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.setting-notification-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.setting-notification-labels {
    display: flex;
    flex-direction: column;
}

.setting-notification-label {
    font-size: 16px;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.setting-notification-description {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.setting-notification-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 25px;
}

.setting-notification-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.setting-notification-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(120, 120, 128, 0.16);
    transition: 0.3s;
    border-radius: 30px;
}

.setting-notification-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
}

.setting-notification-switch input:checked+.setting-notification-slider {
    background-color: var(--primary-color);
}

.setting-notification-switch input:checked+.setting-notification-slider:before {
    transform: translateX(18px);
}

.setting-notification-reset {
    background: none;
    border: none;
    color: var(--red-color);
    font-size: 14px;
    cursor: pointer;
    font-family: var(--font-family-primary)
}

.setting-appearance-wrapper {
    background-color: transparent;
    margin: 40px auto 0 auto;
    width: 60%;
}

.setting-appearance-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-appearance-divider {
    border-color: var(--border-color);
    margin-bottom: 20px;
}

.setting-appearance-subtitle {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-appearance-description {
    font-size: 16px;
    color: var(--gray-dark);
    margin-bottom: 20px;
}

.setting-appearance-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.setting-appearance-card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.setting-appearance-image {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
    object-fit: cover;
    cursor: pointer;
}

.setting-appearance-image.selected {
    border: 2px solid var(--primary-color);
}

.setting-appearance-label {
    text-align: start;
    margin-top: 8px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.setting-appearance-check {
    position: absolute;
    bottom: 25%;
    right: 8px;
    width: 25px;
    height: 25px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
}

.manage-account-setting-wrapper {
    padding: 10px 16px 16px 16px;
}

.manage-account-setting-title {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    cursor: pointer;
}

.manage-account-setting-divider {
    border: 1px solid var(--border-color);
    margin: 20px 0;
}

.manage-account-setting-delete {
    color: var(--red-color);
    font-size: 18px;
    cursor: pointer;
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.manage-account-setting-heading {
    font-size: 24px;
    font-family: var(--font-family-primary);
    font-weight: 500;
    color: var(--text-color);
}

.manage-account-setting-description {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    margin: 12px 0 24px;
}

.manage-account-setting-form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.manage-account-setting-form {
    width: 40%;
}

.manage-account-setting-button {
    width: 100%;
    padding: 10px;
    background-color: var(--red-color);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.manage-account-setting-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.manage-account-setting-overlay-box {
    background-color: var(--gray-background);
    padding: 24px;
    border-radius: var(--border-radius);
    width: 40%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.manage-account-setting-overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 18px;
    color: var(--text-color);
}

.manage-account-setting-overlay-header button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;
}

.manage-account-setting-overlay-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 16px;
}

.manage-account-setting-radio-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: var(--text-color);
    padding: 10px 16px;
    border-radius: var(--border-radius);
    background-color: var(--gray-background-light);
    cursor: pointer;
}

.manage-account-setting-radio-label:hover {
    background-color: var(--mobile-search-input);
}

.manage-account-setting-radio-label span {
    flex-grow: 1;
    text-align: left;
}

.manage-account-setting-radio-label input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid var(--gray-dark);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
}

.manage-account-setting-radio-label input[type="radio"]:checked {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.manage-account-setting-radio-label input[type="radio"]:checked::before {
    content: "";
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.support-setting-container {
    padding: 20px 30px;
}

.support-setting-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2%;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.support-setting-subheading {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    margin-bottom: 3%;
}

.support-setting-summary {
    margin-bottom: 24px;
}

.support-setting-summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.support-setting-summary-label {
    font-weight: 500;
    color: var(--gray-light);
    font-size: 14px;
    font-weight: var(--font-family-primary);
}

.support-setting-summary-value {
    font-weight: 500;
    color: var(--gray-light);
    font-size: 14px;
    font-weight: var(--font-family-primary);
}

.support-setting-evidence-box {
    border: 2px dashed var(--active-tab);
    border-radius: var(--border-radius);
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.2s ease;
}

.support-setting-evidence-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--gray-dark);
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.support-setting-evidence-icon {
    color: var(--gray-dark);
}

.support-setting-evidence-content {
    color: var(--gray-dark);
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.support-setting-disclaimer {
    font-size: 14px;
    color: var(--gray-dark);
    margin: 24px 0;
    font-family: var(--font-family-primary);
}

.password-security-setting-container {
    padding: 20px;
}

.password-security-setting-section {
    margin-bottom: 30px;
}

.password-security-setting-section-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 4px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.password-security-setting-section-subtitle {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    margin-bottom: 16px;
    font-weight: 400;
}

.password-security-setting-item {
    padding: 12px 0;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
}

.password-security-setting-item-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.password-security-setting-mt-4 {
    margin-top: 40px;
}

.password-security-change-password-card {
    background-color: transparent;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 24px;
    margin-top: 25px;
    width: 45%;
}

.password-security-change-password-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.password-security-change-password-description {
    font-size: 12px;
    color: var(--gray-light);
    margin-bottom: 20px;
    font-family: var(--font-family-primary);
}

.password-security-change-password-button {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    font-family: var(--font-family-primary);
    font-size: 14px;
    border: none;
    color: white;
    font-weight: 500;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.password-security-two-factor-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.password-security-two-factor-note {
    font-size: 12px;
    color: var(--gray-dark);
    margin-bottom: 16px;
    margin-top: -8px;
}

.password-security-note {
    font-size: 16px;
    color: var(--text-color);
    margin-bottom: 16px;
    font-family: var(--font-family-primary);
}

.password-security-change-password-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.password-security-change-password-close {
    color: var(--gray-light);
    font-size: 24px;
    cursor: pointer;
    transition: color 0.2s;
}

.password-security-change-password-icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-light-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-security-change-password-icon-lock {
    font-size: 25px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-security-login-alerts-email-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.password-security-login-alerts-email-text {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.always-on {
    font-size: 14px;
    color: var(--green-text);
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.main-email {
    color: var(--text-color);
}