.wallet-main-content {
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 30px 50px;
}

.wallet-heading {
    font-size: 28px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.wallet-border {
    border: 1px solid var(--border-color);
}

.wallet-header {
    display: flex;
    gap: 15px;
}

.wallet-box {
    flex: 1;
    border-radius: 12px;
    padding: 20px;
}

.bg-light-blue {
    background: linear-gradient(112deg, #BAE8FB 0%, #B2B1FD 100%);
}

.bg-purple {
    background: linear-gradient(135deg, #4A24E3, #975eff 100%);
}

.wallet-subtitle {
    font-size: 16px;
    margin-bottom: 8px;
}

.wallet-balance {
    font-size: 32px;
    font-weight: 600;
}

.wallet-actions {
    display: flex;
}

.wallet-btn {
    flex: 1;
    padding: 10px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    background-color: var(--story-input);
}

.withdraw-btn {
    color: var(--red-color);
}

.deposit-btn {
    color: var(--green-text);
}

.transaction-history-heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin-bottom: 4px;
}

.transaction-history-date {
    font-size: 14px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.wallet-transaction {
    background-color: var(--story-input);
    border-radius: 10px;
    color: var(--text-color);
    transition: background-color 0.2s ease;
}

.wallet-transaction:hover {
    background-color: var(--gray-background);
}

.wallet-transaction-heading {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.wallet-transaction {
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.text-success {
    color: var(--green-text) !important;
}

.text-danger {
    color: var(--red-color) !important;
}

.text-warning {
    color: var(--orange-text) !important;
}

.wallet-no-transaction {
    text-align: center;
    padding-top: 3rem;
    font-size: 0.95rem;
}

.wallet-main-source-inline {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.wallet-main-bank-icon {
    font-size: 16px;
}

.transaction-filter-btn {
    width: 40px;
    height: 40px;
    background-color: var(--neutral-overlay);
    border-radius: 50%;
    border: none;
}

.transaction-filter-icon {
    color: var(--text-color)
}

.wallet-transaction {
    color: var(--active-tab);
    font-family: var(--font-family-primary);
    font-size: 13px;
}

.wallet-transaction-amount {
    font-size: 20px;
    font-family: var(--font-family-primary);
}

.wallet-no-transaction-icon {
    color: var(--gray-light);
}

.wallet-no-transaction-description {
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.wallet-detail-wrapper {
    padding: 0 1rem;
    color: #fff;
}

.wallet-detail-title {
    font-weight: 600;
    margin-bottom: 1rem;
}

.wallet-detail-amount {
    font-size: 24px;
    font-weight: bold;
}

.wallet-pill {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 12px;
    border-radius: 20px;
    width: fit-content;
    margin: 0 auto;
}

.wallet-pill-success {
    background-color: #1d2f1f;
    color: #58d68d;
}

.wallet-pill-failed {
    background-color: #3a1e1e;
    color: #ff6b6b;
}

.pill-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
}

.wallet-detail-status-card {
    border-radius: 10px;
    padding: 1rem;
}

.wallet-card-success {
    background-color: #0e1e11;
    border: 1px solid #2e8b57;
}

.wallet-card-failed {
    background-color: #2b1616;
    border: 1px solid #b34a4a;
}

.wallet-detail-step {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    margin-bottom: 1rem;
}

.step-icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-top: 5px;
}

.step-icon.done {
    background-color: #58d68d;
}

.step-icon.failed {
    background-color: #ff4c4c;
}

.step-line {
    width: 2px;
    height: 40px;
    background-color: #555;
    margin-top: 5px;
}

.transparent-line {
    background-color: transparent;
}

.step-info {
    flex-grow: 1;
}

.step-label {
    font-size: 13px;
    color: #bbb;
    margin-bottom: 2px;
}

.step-source {
    font-size: 14px;
    font-weight: 500;
}

.step-time {
    font-size: 12px;
    color: #777;
    margin-top: 2px;
}

.wallet-transfer-info p {
    margin: 0.5rem 0;
    font-size: 14px;
}