.notification-main-content {
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 1%;
}

.notification-title {
    font-size: 36px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    padding: 3% 3%;
}

.notifications-category {
    padding: 0 3%;
}

.notification-category-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.notification-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.notification-post-thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
}

.notification-item {
    padding: 4px 0;
}

.notification-text {
    margin: 0;
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.notification-comment {
    font-size: 16px;
    margin: 3px 0;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.notification-time {
    font-size: 14px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.friend-request-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.friend-request-content {
    background: var(--background-color);
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    height: 100vh;
}

.friend-request-title {
    margin-top: 10px;
    font-size: 24px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.back-button {
    position: absolute;
    margin-top: 10px;
    top: 10px;
    left: 10px;
    font-size: 24px;
    color: var(--text-color);
    background: none;
    border: none;
    cursor: pointer;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@media (max-width: 1024px) {
    .notification-main-content {
        width: calc(100% - 70px);
        padding: 5%;
    }

    .friend-request-link {
        font-size: 16px;
        color: var(--primary-color);
        display: block;
    }

    .home-friend-suggestions {
        padding: 6px 20px;
    }
}


@media (max-width: 767px) {

    .notification-main-content {
        margin-bottom: 40px;
        width: calc(100% - 0px);
        padding: 0;
        margin-top: 60px;
        overflow-y: auto;
    }

    .notification-title {
        font-size: 24px;
    }

    .friend-request-link {
        font-size: 16px;
        color: var(--primary-color);
    }

    button.btn-primary {
        white-space: nowrap;
    }

    .request-buttons {
        white-space: nowrap;

    }

    .friend-suggestions-title {
        display: none;
    }

    .home-friend-suggestions {
        padding: 6px 0;
    }
}