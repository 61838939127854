.search-main-content {
    padding: 10px;
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.search-page-bar {
    position: relative;
    padding: 0 10%;
}

.search-page-bar input {
    width: 100%;
    background: transparent;
    border: 1px solid var(--gray-light);
    border-radius: 25px;
    color: var(--gray-light);
    outline: none;
    margin-top: 5px;
    margin-bottom: 0;
}

.search-page-bar input:focus {
    box-shadow: none;
}

.search-page-bar i {
    position: absolute;
    top: 52%;
    right: 10%;
    transform: translateY(-50%);
    color: var(--gray-light);
    font-size: 20px;
    cursor: pointer;
}

.tabs-container {
    padding: 0 8%;
    position: relative;
}

.tabs {
    display: flex;
    justify-content: space-around;
    position: relative;
    background-color: var(--gray-background);
    border: none;
    border-radius: 25px;
    padding: 8px;
    overflow: hidden;
}

.tab-active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 20%;
    background-color: var(--active-tab);
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
}

.tab-btn {
    color: var(--gray-light);
    font-size: 16px;
    font-family: var(--font-family-primary);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    flex: 1;
    text-align: center;
    z-index: 1;
}


.tab-btn.active {
    color: #ffffff;
}

.flicks-grid {
    margin-top: 8px;
}

.flicks-grid img {
    width: 100%;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
}

.flicks-grid img:hover {
    transform: scale(1.05);
}

.search-main-quest-card {
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.search-main-quest-image-wrapper {
    position: relative;
}

.search-main-quest-image {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.search-main-quest-overlay-top-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-main-quest-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.search-main-quest-user-info {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.search-main-quest-user-name {
    font-size: 14px;
    color: white;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.search-main-quest-user-username {
    font-size: 12px;
    color: white;
    opacity: 0.8;
    font-family: var(--font-family-primary);
}

.search-main-quest-overlay-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.search-main-quest-status {
    color: #FFF;
    text-shadow: 1px 0.5px 2px rgba(0, 0, 0, 0.25);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-main-quest-icon-circle {
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
}

.search-main-quest-icon svg {
    color: white;
    cursor: pointer;
    font-size: 16px;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.search-main-quest-title-wrapper {
    position: absolute;
    bottom: 27%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
}

.search-main-quest-title {
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: var(--font-family-primary);
}

.search-main-quest-go-flick {
    font-size: 16px;
    font-weight: 500;
    color: #34c759;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
    font-family: var(--font-family-primary);
}

.search-main-quest-overlay-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30%;
}

.search-main-quest-description {
    font-size: 14px;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    position: relative;
    color: var(--quest-description-color);
    font-family: var(--font-family-primary);
}

.search-main-quest-amount {
    font-size: 24px;
    font-weight: bold;
    font-family: var(--font-family-primary);
    color: white;
}

.search-heading h5 {
    color: var(--text-color);
}

.search-overlay {
    top: 120%;
    left: 5%;
    z-index: 10;
    width: 90%;
    background-color: var(--gray-background);
    border-radius: 12px;
    padding: 27px 12px;
    cursor: pointer;
}

.search-chip {
    background-color: var(--gray-background-light);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: var(--gray-light);
    padding: 2px 10px;
    border: 0;
    border-radius: 8px;
}

.suggestion-overlay {
    top: 120%;
    left: 0;
    z-index: 12;
    width: 100%;
    background-color: var(--gray-background);
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
}

.suggestion-section h6 {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.suggestion-btn-link {
    background: none;
    border: none;
    color: var(--primary-color);
    font-size: 14px;
    cursor: pointer;
}

.suggestion-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
}

.suggestion-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.suggestion-name {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.suggestion-username {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.suggestion-button {
    padding: 4px 28px;
    font-size: 14px;
    border-radius: 6px;
    color: white;
    background-color: var(--primary-color);
    border: none;
}

.suggestion-following-button {
    padding: 4px 28px;
    font-size: 14px;
    border-radius: 6px;
    color: white;
    background-color: var(--gray-dark);
    border: none;
}

.suggestion-flicks-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.suggestion-flicks-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
    width: 100%;
    padding: 20px 0;
}

.suggestion-flick-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    transition: transform 0.2s ease-in-out;
}

.suggestion-flick-image {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.suggestion-flick-card:hover {
    transform: scale(1.05);
}

.suggestion-flick-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(50px);
    padding: 5px 7px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
}

.suggestion-avatar-icon {
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 50%;
    object-fit: cover;
}

.suggestion-user-name {
    font-size: 10px;
    font-weight: 600;
    color: white;
    font-family: var(--font-family-primary);
}

.suggestion-song-name {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.suggestion-song-username {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.suggestion-tag-name {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.suggestion-tag-username {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.suggestion-user-handle {
    font-size: 8px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.suggestion-music-avatar {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
}

.suggestion-tag-avatar {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    object-fit: cover;
}

.suggestion-quest-image {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
}

.suggestion-quest-name {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.suggestion-quest-description {
    font-size: 10px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;

}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@media (max-width: 1024px) {
    .search-main-content {
        width: calc(100% - 70px);
    }
}

@media (max-width: 767px) {
    .search-main-content {
        margin-bottom: 40px;
        width: calc(100% - 0px);
        padding: 0;
        margin-top: 40px;
        overflow-y: auto;
    }

    .tabs-container {
        padding: 0 2%;
    }

    .tabs-container {
        padding: 0 4% 20% 4%;
    }

    .search-page-bar {
        position: relative;
        padding: 2% 4%;
    }

    .search-page-bar input {
        background: var(--mobile-search-input);
        border: none;
        border-radius: 8px;
    }

    .search-page-bar input:focus {
        background: var(--mobile-search-input);
        border: none;
        border-radius: 8px;
    }

    .search-page-bar input::placeholder {
        font-family: var(--font-family-primary);
        color: var(--gray-dark);
        font-weight: 300;
    }

    .search-page-bar i {
        right: 2%;
        color: var(--gray-dark);
    }

    .suggestion-overlay {
        background-color: var(--background-color);
        border-radius: 0;
        top: 100%;
    }

    .flick-section,
    .quest-section {
        display: none;
    }

    .people-section,
    .music-section,
    .tag-section {
        display: flex;
        flex-direction: column;
    }

    .tabs {
        justify-content: space-around;
        background-color: transparent;
        border-bottom: 1px solid var(--gray-dark);
        border-radius: 0;
        padding: 8px 0;
    }

    .tab-active-bg {
        background-color: transparent;
        border-bottom: 2px solid var(--text-color);
        border-radius: 0;
        transition: all 0.3s ease-in-out;
    }

    .tab-btn {
        color: var(--gray-dark);
    }


    .tab-btn.active {
        color: var(--text-color);
    }

}