html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
}

.login-page {
    display: flex;
    width: 100%;
    height: 100dvh;
    align-items: stretch;
    overflow: hidden;
    flex-direction: row;
}

.left-section,
.right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.right-section {
    background-color: var(--background-color);
    padding: 20px;
}

.left-section {
    background-color: var(--brand-color);
    padding: 20px;
    background-image: url('../assets/compressed/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}



.phone-images {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.phone-images img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.phone {
    width: 100%;
    object-fit: cover;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    opacity: 0;
    margin-right: 80px;
    transform: scale(1.05);
}

.phone.transition {
    opacity: 1;
    transform: scale(1);
}

.right-section {
    min-height: 100vh;
    padding: 2vh 2vw;
}

.logo-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.logo {
    width: 50px;
    height: 50px;
    margin-right: 8px;
}

.content {
    color: var(--text-color);
    font-family: var(--font-family-secondary);
    font-size: 32px;
    font-weight: 400;
    margin-bottom: -10px;
}

.login-box {
    background-color: var(--login-card-color);
    padding: 30px;
    border-radius: 15px;
    width: 60%;
    padding: 2vh 2vw;
    height: auto;
    box-shadow: var(--login-card-shadow);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-box h2 {
    color: var(--text-color);
    margin-bottom: 20px;
    font-family: var(--font-family-secondary);
    font-size: 25px;
    text-align: center;
}

.forgot-password {
    color: var(--primary-color);
    text-align: right;
    font-size: 12px;
    margin-top: 8px;
}

.or {
    position: relative;
    margin: 50px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.or::before,
.or::after {
    content: "";
    flex: 1;
    height: 1px;
    background: var(--gray-dark);
    margin: 0 10px;
}

#or {
    text-align: center;
    padding: 8px 12px;
    background-color: var(--login-card-color);
    border-radius: 50%;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    color: var(--gray-dark);
}

.signup-prompt {
    color: var(--gray-light);
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    margin: 30px 0 10px 0;
}

.signup-link {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 12px;
}

.form-control {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--gray-light);
    border-radius: var(--border-radius);
    color: var(--gray-light);
}

.form-control::placeholder {
    color: var(--gray-light);
    opacity: 1;
}

.form-control:focus {
    border-color: var(--gray-light);
    background-color: transparent;
    color: var(--gray-light);
    box-shadow: none !important;
    outline: none;
}

.form-control:focus::placeholder {
    color: var(--gray-dark);
}

.error-message {
    color: var(--red-color);
    font-family: var(--font-family-primary);
    font-size: 12px;
}

.social-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.social-icon {
    width: 26px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
    color: var(--text-color);
}

.social-icon img:hover {
    transform: scale(1.1);
}

.button {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: var(--primary-color);
}

.get-the-app {
    text-align: center;
    margin-top: 20px !important;
}

.icon {
    width: 32px;
    height: 32px;
    transition: color 0.3s ease;
}

.info-text {
    color: var(--gray-dark);
    text-align: center;
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 10px;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
}

input[type="date"] {
    text-align: left;
}

.form-control {
    position: relative;
}

@media screen and (max-width: 1080px) {
    .login-page {
        flex-direction: column;
        height: auto;
    }

    .left-section {
        display: none;
    }

    .right-section {
        padding: 20px;
        width: 100%;
    }

    .phone-images {
        width: 100%;
        min-height: 400px;
    }

    .login-box {
        width: 50%;
        padding: 30px;
        height: auto;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .signup-prompt {
        margin-top: 50px;
    }

    .icons {
        flex-direction: row;
    }
}

@media screen and (max-width: 900px) {
    .login-page {
        flex-direction: column;
        height: auto;
    }

    .left-section {
        display: none;
    }

    .right-section {
        width: 100%;
        padding: 20px;
    }

    .phone-images {
        width: 100%;
        min-height: 300px;
    }

    .login-box {
        width: 60%;
        padding: 20px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .signup-prompt {
        margin-top: 40px;
    }

    .icons {
        flex-direction: row;
    }
}

@media screen and (max-width: 768px) {
    .login-page {
        flex-direction: column;
        height: auto;
    }

    .left-section {
        display: none;
    }

    .right-section {
        width: 100%;
        padding: 20px;
    }

    .phone-images {
        width: 100%;
        min-height: 250px;
    }

    .login-box {
        width: 60%;
        padding: 20px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .signup-prompt {
        margin-top: 30px;
    }

    .icons {
        flex-direction: row;
    }
}

@media screen and (max-width: 500px) {
    .login-page {
        flex-direction: column;
        height: auto;
    }

    .left-section {
        display: none;
    }

    .right-section {
        width: 100%;
        padding: 15px;
    }

    .phone-images {
        width: 100%;
        min-height: 200px;
    }

    .login-box {
        width: 90%;
        padding: 10px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .signup-prompt {
        margin-top: 20px;
    }

    .icons {
        flex-direction: row;
    }
}

@media screen and (max-height: 700px) {
    .login-box {
        padding: 15px;
        max-width: 55%;
        gap: 15px;
    }

    .login-box h2 {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    .form-control {
        height: 36px;
        font-size: 0.85rem;
        padding: 8px 15px;
    }

    .button {
        height: 36px;
        font-size: 0.9rem;
        margin-top: 10px;
    }

    .social-icon {
        width: 24px;
    }

    .signup-prompt {
        font-size: 11px;
        margin-top: 15px;
    }
}