:root {
    --stories-height: 16vh;
    --spacing-buffer: 4vh;
    --post-container-height: calc(100vh - var(--stories-height) - var(--spacing-buffer));
}

.home-page {
    height: 100vh;
    background-color: var(--background-color);
}

.home-left-sidebar,
.home-right-sidebar {
    position: sticky;
}

.home-right-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
}

.home-left-sidebar {
    height: 100vh;
    overflow-y: auto;
    border-right: 1px solid var(--border-color);
}

.home-left-sidebar .home-logo {
    width: 50px;
    height: 50px;
    margin-right: 8px;
}

.home-left-sidebar .home-content {
    color: var(--text-color);
    font-family: var(--font-family-secondary);
    font-size: 28px;
    font-weight: 400;
    margin-bottom: -10px;
}

.nav {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 25px;
}


.nav-item .nav-link {
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 18px;
    font-weight: 300;
    padding-left: 0;
}

.nav-item .nav-link .icon {
    height: 24px;
    width: 24px;
    color: var(--icon-color);
}

.nav-item .nav-link.active {
    color: var(--text-color);
    font-weight: 600;
}

.home-main-content {
    padding: 10px;
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
}

hr {
    border: 0.7px solid var(--border-color);
    opacity: 70%;
}

.story-section {
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--background-color);
    padding: 2px 10px 2px 10px;
    border-radius: 24px;
    box-shadow: 0px 12px 16px -4px var(--story-shadow);
    z-index: 1;
    overflow: hidden;
    height: var(--stories-height);
}

.stories-container {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding: 6px 12px;
}

.scroll-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
}

.left-arrow {
    left: 0.5rem;
}

.right-arrow {
    right: 0.5rem;
}


.story-card {
    min-width: 70px;
    text-align: center;
}

.story-ring {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.story-ring::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 3px;
    background: linear-gradient(to bottom, #5006B5, #D255FD, #4D92F1, #4D3AC9);
    -webkit-mask: radial-gradient(circle, transparent 64%, black 66%);
    mask: radial-gradient(circle, transparent 64%, black 66%);
}

.story-image {
    position: relative;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    object-fit: cover;
    background-color: transparent;
    border: 3px solid transparent;
}

.story-name {
    margin-top: 5px;
    font-size: 12px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.story-name {
    font-size: 0.9rem;
    margin-top: 5px;
}

.add-story-icon {
    position: absolute;
    bottom: -5px;
    right: 3px;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1px 3px 1px;
    border: 3px solid var(--background-color);
}

.plus-icon {
    color: white;
    font-size: 18px;
}

.posts-container {
    overflow-y: auto;
    height: var(--post-container-height);
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    height: 81vh;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.home-post {
    position: relative;
    width: 32vw;
    height: var(--post-container-height);
    margin: auto;
    flex-shrink: 0;
    border-radius: 20px;
}

.post-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;
    width: 100%;
    max-width: 700px;
    scroll-snap-align: start;
    height: 100%;
}

.post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

}

.post-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    /* background: linear-gradient(to top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0) 100%); */
    z-index: 3;
    pointer-events: none;
    border-radius: 20px;
    width: 94%;
}

.post-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;
    z-index: 4;
    width: 80%;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.post-data {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-family-primary);
    color: #fff;
    letter-spacing: 0.5px;
}


.top-avatar {
    position: absolute;
    width: 65px;
    height: 65px;
    z-index: 5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-avatar::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(to bottom, #5006B5, #D255FD, #4D92F1, #4D3AC9);
    -webkit-mask: radial-gradient(circle, transparent 64%, black 66%);
    mask: radial-gradient(circle, transparent 64%, black 66%);
}

.top-avatar .avatar {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    background-color: transparent;
    border: 3px solid transparent;
    /* margin-left: 20%; */
}

.username {
    font-weight: 500;
    margin: 0;
    color: #fff;
    font-family: var(--font-family-primary);
    font-size: 16px;
}

.song-info {
    margin-top: 8px;
}

.song-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--glassmorphism);
    backdrop-filter: blur(5px);
    padding: 6px 12px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.post-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.song-avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

/* .dot {
    font-size: 16px;
    font-weight: bold;
} */

.bi-heart-fill {
    color: var(--red-color);
}

.post-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    gap: 40px;
    cursor: pointer;
}

.post-icons .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-size: 24px;
    color: var(--text-color);
}

.post-icons .icon span {
    font-size: 13px;
}

.mute-btn {
    position: absolute;
    bottom: 10px;
    right: 50px;
    background: var(--glassmorphism);
    border: none;
    border-radius: 50%;
    color: var(--text-color);
    padding: 10px 15px;
    cursor: pointer;
    z-index: 6;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.home-user-details {
    padding: 15px 0;
}

.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.username {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
}

.person-name {
    font-size: 12px;
    color: var(--gray-dark);
}

.user-icons {
    color: var(--text-color);
    font-size: 22px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.home-friend-suggestions {
    padding: 6px 0;
}

.friend-suggestions-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.friend-request-number {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.reject-button {
    border-radius: 6px;
    font-size: 13px;
    padding: 4px 15px;
    background-color: transparent;
    color: var(--text-color);
    border: 2px solid var(--gray-light);
}

.reject-button:hover {
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--gray-light);
}

.friend-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.music-avatar {
    width: 40px;
    height: 40px;
    border-radius: 10%;
    object-fit: cover;
}

.music-icon button {
    color: var(--text-color);
}

.friend-username {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.friend-name {
    font-size: 12px;
    color: var(--gray-dark);
}

button.btn-primary {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 6px;
    font-size: 13px;
    padding: 4px 15px;
    font-family: var(--font-family-primary);
}

button.btn-secondary {
    background-color: transparent !important;
    color: var(--text-color) !important;
    border: 2px solid var(--gray-light);
    border-radius: 6px;
    font-size: 13px;
    padding: 4px 15px;
    font-family: var(--font-family-primary);
}

button.btn-outline-light:hover {
    background-color: transparent;
}

.btn-following {
    border: none;
    color: var(--text-color);
    background-color: transparent;
    cursor: pointer;
}

.post-caption button {
    font-family: var(--font-family-primary);
    font-size: 14px;
}

.btn-following:hover {
    text-decoration: none;
}

button.btn-primary:hover {
    background-color: var(--primary-color);
}

.remove-icon {
    font-size: 24px;
    color: var(--text-color);
    cursor: pointer;
}

.see-all {
    color: var(--primary-color);
    font-size: 14px;
    cursor: pointer;
}

.see-all i {
    font-size: 16px;
    color: var(--primary-color);
}

.see-all:hover {
    color: var(--primary-color);
}

.bottom-nav .nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.comment-section-header,
.share-section-header,
.flick-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.comment-section-header h3,
.share-section-header h3,
.flick-section-header h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    font-family: var(--font-family-primary);
    color: var(--text-color)
}

.comment-section-header span {
    font-size: 18px;
    color: var(--gray-dark)
}

.cancel-btn {
    background: none;
    border: none;
    font-size: 20px;
    color: var(--gray-dark) !important;
    cursor: pointer;
}

/* Comment Item */
.comments-list .comment-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.comment-avatar img,
.reply-avatar img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
}

.comment-content {
    margin-left: 10px;
    flex: 1;
}

.comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.comment-header .username {
    font-weight: 600;
    color: var(--text-color);
    font-size: 14px;
    font-family: var(--font-family-primary);
    margin-right: 5px;
}

.comment-header .time {
    font-size: 12px;
    color: var(--gray-light);
    font-weight: 400;
    font-family: var(--font-family-primary);
}

.comment-header .like-btn {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    color: var(--text-color);
}

.comment-header .like-count {
    font-size: 12px;
    display: block;
    color: var(--text-color);
}

.comment-header .like-btn:hover {
    transform: scale(1.1);
}

.comment-text {
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 300;
    margin: 5px 0;
}

.comment-actions {
    display: flex;
    gap: 15px;
    font-size: 14px;
    color: var(--gray-dark);
}

.comment-actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: var(--gray-dark);
}

.comment-actions button:hover {
    text-decoration: underline;
}

.replies-list {
    margin-top: 30px;
    margin-left: 10px;
}

.reply-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.reply-content {
    margin-left: 10px;
    flex: 1;
}

.reply-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.reply-header .username {
    font-weight: 600;
    color: var(--text-color);
    font-size: 14px;
    font-family: var(--font-family-primary);
    margin-right: 5px;
}

.reply-header .time {
    font-size: 12px;
    color: var(--gray-light);
    font-weight: 400;
    font-family: var(--font-family-primary);
}

.reply-header .like-btn {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    color: var(--text-color);
}

.reply-header .like-count {
    font-size: 12px;
    display: block;
    color: var(--text-color);
}

.reply-header .like-btn:hover {
    transform: scale(1.1);
}

.reply-text {
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 300;
    margin: 5px 0;
}

.comment-input-section {
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 95%;
}

.emoji-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.emoji-bar span {
    font-size: 18px;
    cursor: pointer;
}

.emoji-bar span:hover {
    transform: scale(1.2);
}

.comment-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}

.comment-input-wrapper input {
    flex: 1;
    border: none;
    border-radius: 20px;
    padding: 10px 5px;
    font-size: 14px;
    background: transparent;
}

.send-btn {
    background: none;
    border: none;
    font-size: 25px;
    color: var(--text-color);
    cursor: pointer;
    margin-right: -20px;
}

.search-bar {
    padding: 0;
    background-color: rgba(226, 226, 227, 0.15);
    border-radius: 7px;
}

.search-bar input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 7px;
    background: transparent;
}

.search-bar input:focus {
    outline: none;
    border: none;
    box-shadow: none;
    color: var(--text-color);
}

.search-bar input::placeholder {
    color: var(--gray-light);
    opacity: 0.7;
}

.user-list {
    max-height: calc(100% - 55%);
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.user-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.user-item.selected .user-avatar {
    border: 2px solid var(--primary-color);
    border-radius: 50%;
}

.share-flick-tick-mark {
    position: absolute;
    bottom: 25px;
    right: 5px;
    background-color: var(--primary-color);
    color: white;
    font-size: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}


.share-flick-user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 5px;
    object-fit: cover;
}

.share-flick-user-name {
    font-size: 12px;
    color: var(--gray-light);
    word-wrap: break-word;
    font-family: var(--font-family-primary);
    font-weight: 300;
}

.social-icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    cursor: pointer;
    position: absolute;
    height: 30%;
    bottom: 0;
    width: 95%;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: var(--background-color);
}

.social-item {
    text-align: center;
    font-size: 14px;
}

.social-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #444;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #fff;
    text-decoration: none;
    font-size: 40px;
    margin: 0 auto 5px;
    transition: transform 0.3s ease, background 0.3s ease;
}

.social-item.whatsapp a {
    background-color: #25D366;
}

.social-item.share a {
    background-color: #C043D3;
    font-size: 25px !important;
}

.social-item.copy-link a {
    background-color: #FF9800;
    font-size: 25px !important;
}

.social-item.add-story a {
    background-color: #5731E3;
    font-size: 25px !important;
}

.social-item.instagram a {
    background: linear-gradient(45deg, #FFDC80, #FC6C8F, #7A81FF);
}

.social-item.youtube a {
    background-color: #FF0000;
}

.social-item.snapchat a {
    background-color: #FFFC00;
}

.social-item.facebook a {
    background-color: #1877F2;
}

.social-item a:hover {
    transform: scale(1.1);
}

.social-name {
    font-size: 12px;
    color: var(--gray-light);
    word-wrap: break-word;
    font-family: var(--font-family-primary);
    font-weight: 300;
}

.message-card {
    position: fixed;
    width: 340px;
    bottom: 10px;
    z-index: 5;
    background-color: var(--background-color);
    padding: 15px 8px;
    border-radius: 10px;
    box-shadow: 0px 12px 16px -4px rgba(80, 80, 80, 0.20);
}

.message-card h4 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-family-primary);
}

.message-card textarea {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    padding: 8px;
    resize: none;
    background-color: transparent;
}

.message-card textarea:focus {
    outline: none;
    border: none;
    box-shadow: none;
    color: var(--text-color);
}

.message-card button {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 100%;
}

.flick-content .flick-text {
    font-weight: 300;
    font-family: var(--font-family-primary);
    position: relative;
}

.flick-content textarea {
    width: 100%;
    margin-top: 10px;
    background-color: transparent;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    resize: none;
    padding: 10px;
}

.flick-content textarea:focus {
    outline: none;
    border: 1px solid var(--border-color);
    box-shadow: none;
    color: var(--text-color);
    /* height: 100vh; */
}

.flick-button button {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
}

.comment-section-overlay,
.share-section-overlay,
.repost-section-overlay {
    position: absolute;
    bottom: 0;
    width: 94%;
    height: 75%;
    padding: 20px;
    background-color: var(--gray-background);
    z-index: 30;
    border-radius: 20px;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    animation: slide-up 0.3s ease-in-out;
}

.post-container {
    position: relative;
}


.more-options-card {
    top: 30%;
    right: 0;
    background-color: var(--gray-background);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 30;
    color: var(--text-color);
    width: 230px !important;
}

.more-options-card .option {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: var(--font-family-primary);
    font-size: 16px;
}

.more-options-card .report {
    color: var(--red-color);
}

.report-box {
    position: absolute;
    top: -50%;
    right: 250%;
    background-color: var(--gray-background);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 40;
    color: var(--text-color);
    width: 350px;
}

.report-box h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    font-family: var(--font-family-primary);
}

.report-box .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
}

.report-box p {
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.report-options-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.report-option {
    padding: 8px 15px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s ease;
}

.report-option:hover {
    background-color: #3a3a3c;
}

.about-profile-card {
    position: absolute;
    top: -50%;
    right: 250%;
    background-color: var(--gray-background);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 50;
    color: var(--text-color);
    width: 350px;
    display: block;
}

.about-profile-card .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
}

.about-profile-card .profile-header {
    display: flex;
    justify-content: center;
    position: relative;
}

.about-profile-card .profile-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.about-profile-card .profile-info {
    margin-top: 20px;
    text-align: center;
}

.about-profile-card .profile-info .username {
    font-family: var(--font-family-primary);
    font-size: 20px;
}

.about-profile-card .profile-info .date-joined {
    font-size: 16px;
    margin: 10px 0;
}

.about-profile-card hr,
.report-box hr {
    border: 0;
    border-top: 1px solid #68686B;
    margin: 10px 0;
}

.about-profile-card .profile-info .location {
    font-size: 16px;
    margin: 10px 0;
}

.story-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.story-view-header {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.story-view-header .logo-content {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.story-view-header .logo-content .logo {
    width: 54px;
    height: 54px;
    margin-right: 8px;
}

.story-view-header .logo-content .content {
    color: var(--text-color);
    font-family: var(--font-family-secondary);
    font-size: 28px;
    font-weight: 400;
    margin-bottom: -10px;
}

.story-delete-icon {
    position: absolute;
    top: 100px;
    left: 50px;
    transform: translateX(-50%);
    background: none;
    border: none;
    font-size: 24px;
    color: var(--text-color);
    cursor: pointer;
    z-index: 1050;
    color: var(--red-color);
}

.more-options-icon {
    position: absolute;
    top: 100px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    color: var(--text-color);
    cursor: pointer;
    z-index: 1050;
}

.close-button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 24px;
    cursor: pointer;
}

.story-view-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.story-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.story {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.story-view-image {
    object-fit: cover;
}

.current-story {
    transform: scale(1.2);
    z-index: 3;
    margin: 0 64px;
    position: relative;
}

.current-story .story-view-image {
    width: 100%;
    height: 70vh;
    border-radius: 10px;
    filter: blur(0);
}

.story-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px;
    border-radius: 10px;
    box-sizing: border-box;
    background: var(--story-view-shadow);
    z-index: 2;
    width: 100%;
}

.story-info .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.story-info .avatar-section {
    display: flex;
    align-items: center;
    gap: 12px;
}

.story-info .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    z-index: 3;
}

.story-info .details .username {
    font-weight: 500;
    font-size: 14px;
    color: white;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.story-info .details .story-song-info {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    color: white;
}

.story-info .details .story-song-info .story-song-avatar {
    width: 16px;
    height: 16px;
    border-radius: 20%;
    object-fit: cover;
}

.story-info .details .story-song-info .dot {
    font-size: 14px;
    color: white;
    margin: 0 2px;
}

.story-info .icons {
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 3;
    color: white;
}

.story-info .icons i {
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.2s ease;
}


.nearby-story {
    transform: scale(1);
    z-index: 2;
    opacity: 0.9;
    margin: 0 40px;
}

.nearby-story .story-view-image {
    width: 100%;
    height: 40vh;
    border-radius: 10px;
    filter: blur(2px);
}

.far-story {
    transform: scale(1);
    z-index: 1;
    opacity: 0.9;
    margin: 0;
}

.far-story .story-view-image {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    filter: blur(2px);
}

.hidden-story {
    display: none;
}

.story-info-nearby {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.story-info-nearby .avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
}

.story-info-far {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.story-info-far .avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
}

.story-nav-button {
    position: absolute;
    top: 50%;
    background: rgba(82, 82, 84, 0.30);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 0px 6px;
    border-radius: 50%;
    z-index: 4;
}

.story-nav-button.story-left {
    left: calc(50% - 280px);
}

.story-nav-button.story-right {
    right: calc(50% - 280px);
}

.reply-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.reply-input-container {
    flex: 1;
    display: flex;
    align-items: center;
    background: var(--story-input);
    border-radius: 10px;
    padding: 6px;
}

.reply-input {
    flex: 1;
    border: none;
    background: transparent;
    font-size: 14px;
    outline: none;
    padding: 0 4px;
}

.reply-input::placeholder {
    color: var(--gray-light);
}

.reply-input:focus {
    color: var(--gray-light);
}

.emoji-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: var(--gray-light);
    margin-left: 8px;
    padding: 0;
    display: flex;
    align-items: center;
}

.progress-container {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    cursor: pointer;
    z-index: 10;
}

.progress {
    height: 2px;
    flex-grow: 1;
    border-radius: 4px;
    margin: 0 5px;
    display: flex;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(88, 89, 104, 0.5) 50.001%,
            rgba(88, 89, 104, 0.5) 100%);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 100% 50%;
    animation: loader-animation 15s linear forwards;
}

.progress.active {
    animation-name: loader-animation;
}

.progress.passed {
    background-position: 0 0;
}

@keyframes loader-animation {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.viewers-section {
    position: absolute;
    bottom: 10px;
    left: 15px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    border-radius: 8px;
    color: #fff;
}

.viewers-section .avatars {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.viewers-section .viewer-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: -10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.views-text {
    font-size: 14px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    margin: 0;
    font-family: var(--font-family-primary);
    font-style: italic;
}

.story-view-content.shrinked .story-view-image {
    align-items: flex-start;
    transition: margin-top 0.3s ease;
    width: 100%;
    height: 35vh;
    margin-top: 70px;
}

.viewers-container {
    width: 40%;
    height: 60vh;
    background-color: var(--story-input);
    border-radius: 10px 10px 0 0;
    padding: 16px;
    box-shadow: 0 4px 6px var(--shadow-color);
    margin-top: 50px;
    overflow-y: scroll;
    position: relative;
    z-index: 1;
}

.viewer-avatar-container {
    position: relative;
    display: inline-block;
}

.icon-heart,
.icon-fire,
.icon-love {
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: 14px;
}

.viewers-container h6 {
    color: var(--gray-light);
}

.viewers-container hr {
    border: 1px solid var(--gray-dark);
}

.viewers-container .view-heading {
    color: var(--text-color);
    font-size: 16px;
    font-family: var(--font-family-primary);
}

.viewer-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    z-index: 2 !important;
}

.viewer-row .viewer-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.viewer-row .viewer-username {
    flex-grow: 1;
    font-size: 14px;
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.viewer-row .paperplane-icon {
    font-size: 20px;
    color: var(--gray-light);
    cursor: pointer;
}

.viewer-options-card {
    background-color: var(--gray-background);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -100%;
    right: 30%;
    z-index: 10;
    width: 35%;
    padding: 10px;
    animation: fadeIn 0.3s ease;
}

.viewer-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
    z-index: 10;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.block {
    color: var(--red-color);
    font-family: var(--font-family-primary);
}

.storyview-options-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 20px;
    color: var(--gray-light);
    cursor: pointer;
    z-index: 15;
    transition: color 0.3s ease;
}



@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.confirm-box {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: var(--gray-background);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    z-index: 20;
}

.confirm-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.confirm-text {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin: 10px 0;
    line-height: 1.5;
    font-weight: 300;
}

.confirm-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.confirm-button,
.cancel-button {
    width: 48%;
    padding: 6px 12px !important;
    border: none;
    border-radius: 5px;
    font-size: 14px !important;
    cursor: pointer;
    font-family: var(--font-family-primary);
}

.confirm-button {
    background-color: var(--red-color);
    color: #fff;
}

.confirm-button:hover {
    background-color: darkred;
}

.cancel-button {
    background-color: var(--gray-light);
    color: var(--text-color);
}

.cancel-button:hover {
    background-color: var(--gray-dark);
}

.storyview-reply-box {
    position: fixed;
    bottom: 0;
    left: 84%;
    transform: translateX(-50%);
    width: 25%;
    background-color: var(--gray-background);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: start;
    z-index: 50;
}

.storyview-reply-avatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
}

.storyview-reply-text {
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin-bottom: 15px;
    font-weight: 400;
}

.storyview-reply-input-wrapper {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    padding: 10px;
    background-color: none;
    margin-top: 15px;
}

.storyview-reply-input-field {
    flex: 1;
    border: 1px solid var(--gray-light);
    outline: none;
    font-size: 14px;
    padding: 8px 15px;
    font-family: var(--font-family-primary);
    color: var(--text-color);
    background-color: transparent;
    border-radius: 25px;
}

.storyview-reply-input-field::placeholder {
    color: var(--gray-light);
}

.storyview-send-reply-icon {
    color: var(--gray-light);
    cursor: pointer;
    margin-left: 8px;
}

.storyview-reply-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 20px;
    color: var(--gray-light);
    cursor: pointer;
    z-index: 15;
    transition: color 0.3s ease;
}

.story-container-overlay {
    position: fixed;
    top: 45px;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(67, 66, 66, 0.14) 96.44%, rgba(50, 50, 50, 0.08) 182.33%);
    backdrop-filter: blur(60px);
    z-index: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.stories-container-mobile {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    padding: 10px 10px 0 10px;
    scrollbar-width: none;
}

.stories-container-mobile::-webkit-scrollbar {
    display: none;
}

.story-container-overlay.mobile-only {
    display: none;
}

.close-btn {
    position: absolute;
    top: -3px;
    right: -8px;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    z-index: 501;
}

.profile-photo {
    border-radius: 50%;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

/* @media screen and (max-height: 750px) {
    .home-post {
        width: 23vw;
    }
} */

@media (max-width: 1024px) {
    .home-left-sidebar .home-logo {
        margin-right: 0;
    }

    .home-left-sidebar {
        width: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-item .nav-link {
        padding: 8px 16px;
    }

    .home-left-sidebar .home-content,
    .nav-item .nav-link span {
        display: none;
    }

    .home-main-content {
        width: calc(100% - 70px);
        padding-left: 1rem;
        overflow-y: auto;
    }

    .home-post {
        position: relative;
        width: 80%;
        height: 81vh;
        margin: auto;
        flex-shrink: 0;
        border-radius: 20px;
    }

    .post-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        border-radius: 16px;
        width: 100%;
        max-width: 700px;
    }

    .post-image {
        width: 100%;
        height: 81vh;
        width: 100%;
        object-fit: fill;
        border-radius: 20px;

    }

    .post-container::after {
        width: 100%;
    }

    .home-right-sidebar {
        display: none;
    }

    .comment-section-overlay,
    .share-section-overlay,
    .repost-section-overlay {
        height: 65%;
        width: 100%;
        z-index: 900;
    }

    .social-icons {
        background-color: var(--gray-background);
    }

    .user-list {
        max-height: 400px !important;
        overflow-y: auto;
    }

    .share-flick-tick-mark {
        bottom: 25px;
        right: 45px;
    }

    .message-card {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 60;
    }


    .about-profile-card {
        top: -100% !important;
        right: 600% !important;
    }

    .report-box {
        top: -80% !important;
        right: 500% !important;
    }

    .current-story .story-view-image {
        width: 430px;
        height: 650px;
    }

    .far-story .story-view-image {
        display: none;
    }

    .story-info-far {
        display: none;
    }

    .nearby-story {
        transform: scale(1);
        z-index: 2;
        opacity: 0.9;
        margin: 0 20px;
    }

    .nearby-story .story-view-image {
        width: 150px;
        height: 30vh;
        border-radius: 10px;
        filter: blur(2px);
    }

    .story-nav-button.story-left {
        left: calc(50% - 300px);
    }

    .story-nav-button.story-right {
        right: calc(50% - 300px);
    }

    .reply-input-container {
        width: 430px;
    }

    .progress-container {
        width: 430px;
    }

    .story-view-content.shrinked .story-view-image {
        width: 100%;
        height: 25vh;
    }

    .viewers-container {
        width: 70%;
        height: 70vh;
    }

    .viewer-options-card {
        width: 35%;
    }

    .confirm-box {
        top: 30%;
    }

    .storyview-reply-box {
        left: 50%;
        bottom: 70%;
        width: 50%;
    }

    .friend-suggestions-title {
        display: none;
    }

    .story-info {
        width: 430px;
    }
}

@media (min-width: 769px) {
    .top-avatar {
        display: none;
    }
}

@media (max-width: 767px) {
    .home-page {
        flex-direction: column;
    }

    .home-left-sidebar {
        display: none;
    }

    .bottom-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        padding: 10px 0;
        background-color: var(--background-color);
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .bottom-nav .mobile-icon {
        color: var(--icon-color);
        height: 24px;
        width: 24px;
    }

    .home-main-content {
        margin-bottom: 40px;
        width: calc(100% - 0px);
        padding: 0;
        margin-top: 40px;
        overflow-y: auto;
    }

    .story-section {
        display: none
    }


    .home-post {
        width: 100%;
        border-radius: 12px;
        height: 88vh;
    }

    .posts-container {
        padding: 0 0 20px 0 !important;
        width: 100%;
        position: relative;
        height: 88vh;
    }

    .post-image {
        height: 88vh;
        width: 100%;
        object-fit: cover;
        border-radius: 0;
    }

    .post-container {
        height: 88vh;
    }

    .post-container::after {
        width: 100%;
        border-radius: 0;
        height: 88vh;
    }

    .post-icons {
        position: absolute !important;
        bottom: 10px !important;
        right: 20px !important;
        flex-direction: column !important;
        align-items: flex-end !important;
        gap: 30px !important;
        z-index: 19;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8), 0 0 1px rgba(0, 0, 0, 0.5);
    }

    .post-caption {
        position: absolute;
        bottom: 10px;
        left: 20px;
    }

    .post-icons .icon {
        color: #fff !important;
        gap: 2px !important;
        font-size: 30px;
    }

    .mute-btn {
        display: none;
    }

    .top-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: var(--background-color);
        z-index: 700;
        padding: 8px;
    }

    .top-avatar {
        display: flex;
        position: fixed;
        top: 8%;
        left: 5% !important;
    }

    .story-container-overlay.mobile-only {
        display: flex !important;
    }

    .logo-content .logo-img {
        height: 40px;
        object-fit: contain;
    }

    .logo-content .logo-text {
        color: var(--text-color);
        font-family: var(--font-family-secondary);
        font-size: 24px;
        font-weight: 400;
        margin-bottom: -10px;
    }

    .top-nav .top-nav-icon {
        font-size: 24px;
        color: var(--text-color);
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .comment-section-overlay,
    .share-section-overlay,
    .repost-section-overlay {
        width: 100%;
        padding: 10px;
        border-radius: 0;
        height: 75%;
    }

    .share-flick-tick-mark {
        bottom: 20px;
        right: 20px;
    }

    .message-card {
        border-radius: 0;
    }

    .user-list {
        max-height: 300px !important;
    }

    .social-icons {
        background-color: var(--gray-background) !important;
    }

    .about-profile-card {
        top: -80% !important;
        right: 70% !important;
    }

    .report-box {
        top: -80% !important;
        right: 70% !important;
    }

    .story-view-header {
        display: none;
    }

    .story-carousel {
        display: block;
    }

    .current-story {
        margin: 0;
        padding: 0;
        transform: none;
    }

    .current-story .story-view-image {
        width: 100%;
        height: 88vh;
        border-radius: 0;
    }

    .progress-container {
        width: 100%;
    }

    .story-info {
        width: 100%;
        border-radius: 0;
    }

    .story-nav-button.story-left {
        left: calc(50% - 200px);
    }

    .story-nav-button.story-right {
        right: calc(50% - 200px);
    }

    .reply-section {
        position: absolute;
        bottom: 1%;
        left: 0;
        width: 100%;
        padding: 5px;
    }

    .reply-input-container {
        padding: 12px;
        border-radius: 12px;
    }

    .story-view-content.shrinked .story-view-image {
        width: 50%;
        height: 35vh;
        overflow-y: hidden;
    }

    .viewers-container {
        width: 100%;
        height: 50%;
        margin-bottom: 40px;
    }

    .viewer-options-card {
        width: 50%;
        right: 15%;
    }

    .storyview-reply-box {
        left: 50%;
        bottom: 5%;
        width: 100%;
    }

    .confirm-box {
        width: 80%;
    }

}