.quest-main-content {
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
}

.quest-tab-bar {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 0 0 0;
}

.quest-tab-button {
    background: none;
    border: none;
    color: var(--gray-light);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.quest-tab-button.active {
    color: var(--text-color);
    border-bottom: 2px solid var(--text-color);
}

.quest-tab-button:hover {
    color: var(--text-color);
}

.quest-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

.quest-card {
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.quest-image-wrapper {
    position: relative;
}

.quest-image {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.quest-overlay-top-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.quest-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.quest-user-info {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.quest-user-name {
    font-size: 14px;
    color: white;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.quest-user-username {
    font-size: 12px;
    color: white;
    opacity: 0.8;
    font-family: var(--font-family-primary);
}

.quest-overlay-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.quest-status {
    color: #FFF;
    text-shadow: 1px 0.5px 2px rgba(0, 0, 0, 0.25);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.quest-icon-circle {
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
}

.quest-icon {
    color: white;
    cursor: pointer;
    font-size: 16px;
    /* filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25)); */
}

.liked {
    color: white;
}

.quest-title-wrapper {
    position: absolute;
    bottom: 35%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 12px;
    width: 100%;
}

.quest-title {
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: var(--font-family-primary);
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.quest-overlay-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 12px 4px 12px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    height: auto;
}

.quest-overlay-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
}

.quest-description {
    font-size: 14px;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    position: relative;
    color: var(--quest-description-color);
    font-family: var(--font-family-primary);
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.quest-amount {
    font-size: 24px;
    font-weight: bold;
    font-family: var(--font-family-primary);
    color: white;
}

.onflick {
    font-size: 14px;
    font-weight: 500;
    color: var(--green-text);
    background: var(--green-background);
    font-family: var(--font-family-primary);
    border-radius: var(--border-radius);
    border: none;
    padding: 2px 16px;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.goflick {
    font-size: 14px;
    font-weight: 500;
    color: var(--orange-text);
    background: var(--orange-background);
    font-family: var(--font-family-primary);
    border-radius: var(--border-radius);
    border: none;
    padding: 2px 16px;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.quest-date {
    font-size: 12px;
    color: white;
    font-weight: 400;
    font-family: var(--font-family-primary);
}

.quest-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quest-action-btn {
    background: var(--quest-tools);
    border: none;
    padding: 6px 30px;
    margin-right: 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--gray-dark);
}

.quest-action-btn:hover {
    background: var(--quest-tools);
    border: none;
    color: var(--text-color);
}

.quest-action-btn:active {
    background: var(--quest-tools);
    border: none;
    color: var(--text-color);
}

.quest-search-input {
    border: none;
    background: var(--quest-tools);
    border-radius: var(--border-radius);
    padding: 6px 12px;
    border-radius: var(--border-radius);
    outline: none;
    color: var(--text-color);
    width: 400px;
}

.quest-search-input::placeholder {
    color: var(--gray-dark);
}

.quest-create-btn {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 6px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.quest-create-btn i {
    margin-right: 5px;
}

.profile-quest-container {
    width: 100%;
}

.profile-quest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--gray-background);
    padding: 12px 15px;
    border-radius: 16px;
}

.profile-quest-info {
    display: flex;
    align-items: center;
}

.profile-quest-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.profile-quest-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-quest-name {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.profile-quest-username {
    font-size: 16px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.profile-quest-stats {
    font-size: 14px;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.profile-quest-balance {
    text-align: right;
}

.profile-quest-balance-label {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.profile-quest-balance-amount {
    font-size: 26px;
    font-weight: bold;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.profile-quest-tabs {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
    justify-content: space-evenly;
    position: relative;
}

.profile-quest-tab-button {
    background: none;
    border: none;
    padding: 15px 0;
    color: var(--gray-dark);
    cursor: pointer;
    font-size: 16px;
    flex: 1;
    text-align: center;
    position: relative;
}

.profile-quest-tab-button.active {
    color: var(--text-color);
}

.profile-quest-tab-button.active::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--text-color);
}

.quest-applicants {
    font-size: 16px;
    font-family: var(--font-family-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
    cursor: pointer;
}

.quest-status {
    padding: 4px 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: var(--border-radius);
    display: inline-block;
    text-align: center;
    border: none;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
    background-color: transparent;
}

.in-review {
    color: #FFBF00;
}

.opened {
    color: #32CD32;
}

.closed {
    color: #FF0033;
}

.pending {
    color: #FFBF00;
}

.approved {
    color: #32CD32;
}

.rejected {
    color: #9e9e9e;
}

.create-quest-page {
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
}

.create-quest-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    padding: 15px 8%;
}

.create-quest-header-content {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.create-quest-title {
    font-size: 24px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin: 0;
}

.create-quest-description {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 400;
    margin: 0;
}

.create-quest-cancel-icon {
    position: fixed;
    top: 5%;
    right: 5%;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--gray-dark);
    z-index: 50;
}

.create-quest-container {
    background: var(--create-container);
    width: 60%;
    margin: 0 auto;
    padding: 10px 30px 30px 30px;
    border-radius: 16px;
}

.create-quest-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    margin-top: 10px;
    margin-bottom: 8px;
    display: block;
    font-family: var(--font-family-primary);
}

.create-quest-input,
.create-quest-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
    border: 1px solid var(--gray-dark);
    border-radius: var(--border-radius);
    background: transparent;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.create-quest-input::placeholder,
.create-quest-textarea::placeholder {
    color: var(--gray-dark);
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.create-quest-input:focus,
.create-quest-textarea:focus {
    box-shadow: none;
    border: 1px solid var(--gray-dark);
    outline: none;
}

.create-quest-upload-box {
    border: 2px dashed var(--gray-dark);
    padding: 20px;
    text-align: center;
    border-radius: var(--border-radius);
    margin-top: 8px;
    margin-bottom: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.create-quest-upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    pointer-events: none;
}

.create-quest-upload-icon {
    font-size: 44px;
    color: var(--primary-color);
}

.create-quest-upload-text {
    text-align: center;
}

.create-quest-upload-title {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.create-quest-upload-subtext {
    font-size: 16px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.create-quest-upload-format {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.create-quest-upload-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.thumbnails-container {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
}

.thumbnails-scroll {
    display: flex;
    gap: 10px;
}

.thumbnail-wrapper {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    cursor: pointer;
}

.thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.thumbnail-image-remove-btn {
    position: absolute;
    top: -8px;
    right: -8px;
    background: var(--red-color);
    height: 24px;
    width: 24px;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 20;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.thumbnail-image-remove-btn svg {
    width: 18px;
    height: 18px;
}

.create-quest-radio-group {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 20px !important;
}

.create-quest-radio-option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
}

.create-quest-radio-option input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid var(--text-color);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
}

.create-quest-radio-option input[type="radio"]:checked {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.create-quest-radio-option input[type="radio"]:checked::before {
    content: "";
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.create-quest-location-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.create-quest-location-button {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 14px;
    color: var(--primary-color);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.create-quest-wallet-container {
    background: transparent;
    border: 1px solid var(--gray-dark);
    padding: 15px;
    border-radius: var(--border-radius);
    text-align: center;
    margin-bottom: 25px !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-quest-wallet-icon {
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 20px;
    color: var(--gray-dark);
    cursor: pointer;
}

.create-quest-eye-icon {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 20px;
    color: var(--gray-dark);
    cursor: pointer;
}

.create-quest-wallet-label {
    font-size: 18px;
    font-weight: 300;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.create-quest-wallet-balance {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.create-quest-description-text {
    font-size: 14px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.create-quest-input-helper-text {
    font-size: 14px;
    color: var(--gray-dark);
}

.create-quest-amount-input-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-dark);
    border-radius: var(--border-radius);
    overflow: hidden;
    background: transparent;
    width: 100%;
}

.create-quest-currency-label {
    background: var(--gray-background);
    color: var(--text-color);
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid var(--gray-dark);
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-quest-amount-input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    padding: 10px;
    font-size: 16px;
    color: var(--text-color);
}

.create-quest-amount-input::placeholder {
    color: var(--gray-dark);
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.create-quest-amount-input-container .form-control,
.create-quest-currency-label.input-group-text {
    border: none;
    box-shadow: none;
}

.create-quest-actions {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 15px;
    padding-right: 20%;
    padding-bottom: 2%;
}

.create-quest-button-cancel {
    background: transparent;
    color: var(--gray-dark);
    padding: 8px 24px;
    border: 1px solid var(--gray-dark);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.create-quest-button-submit {
    background: var(--primary-color);
    color: white;
    padding: 8px 24px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.filter-dropdown {
    position: absolute;
    top: 120px;
    left: 28%;
    transform: translateX(-50%);
    width: 300px;
    background: var(--gray-background);
    border-radius: 10px;
    padding: 20px;
    box-shadow: -3px 4px 8px 2px rgba(0, 0, 0, 0.25);
    z-index: 20;
    color: var(--text-color);
    font-family: var(--font-family-primary)
}

.filter-section {
    margin-bottom: 15px;
}

.filter-heading {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 8px;
    font-family: var(--font-family-primary);
}

.amount-fields {
    display: flex;
    justify-content: space-between;
}

.styled-input {
    width: 48%;
    padding: 8px;
    background: transparent;
    border: 1px solid var(--gray-light);
    border-radius: var(--border-radius);
    color: var(--text-color);
    text-align: center;
    font-size: 14px;
}

.styled-input::placeholder {
    color: var(--gray-light);
}

.styled-input:focus {
    outline: none;
    box-shadow: none;
}

.styled-input::-webkit-outer-spin-button,
.styled-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.styled-input {
    -moz-appearance: textfield;
}

.filter-type-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.filter-type-option input {
    margin-right: 8px;
}

.filter-type-option input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid var(--text-color);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
}

.filter-type-option input[type="radio"]:checked {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.filter-type-option input[type="radio"]:checked::before {
    content: "";
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.location-container {
    margin-bottom: 15px;
    position: relative;
}

.location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
    transition: background 0.3s;
}

.location-header:hover {
    background: #333;
}

.arrow {
    font-size: 16px;
    color: #bbb;
}

.location-search {
    padding: 8px;
    background: transparent;
    border: 1px solid var(--gray-light);
    border-radius: var(--border-radius);
    color: var(--text-color);
    font-size: 14px;
    width: 100%;
    margin-top: 8px;
}

.location-search::placeholder {
    color: var(--gray-light);
}

.location-search:focus {
    outline: none;
    box-shadow: none;
}

.location-dropdown-right {
    position: absolute;
    top: 0;
    right: -630px;
    width: 600px;
    background: var(--gray-background);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 30;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 500px;
    overflow-y: auto;
}

.location-button {
    background: transparent;
    border: 1px solid var(--gray-light);
    padding: 4px 16px;
    border-radius: 20px;
    cursor: pointer;
    color: var(--gray-light);
    transition: background 0.3s, border-color 0.3s;
    font-size: 14px;
}

.location-button.selected {
    background: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
}

.no-results {
    color: #bbb;
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}

.filter-buttons {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
}

.filter-buttons button {
    padding: 6px 16px;
    border-radius: var(--border-radius);
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}

.filter-buttons button:first-child {
    background: transparent;
    border: 1px solid var(--gray-light);
    color: var(--gray-light);
    font-size: 14px;
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.filter-buttons button:last-child {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    font-size: 14px;
    font-family: var(--font-family-primary);
    padding: 6px 20px;
    font-weight: 500;
}

.sort-dropdown {
    width: 270px;
    background: var(--gray-background);
    border-radius: 10px;
    padding: 20px;
    box-shadow: -3px 4px 8px 2px rgba(0, 0, 0, 0.25);
    z-index: 20;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    position: absolute;
    top: 50px;
    right: -40px;
    z-index: 10;
}

.sort-option {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
}

.sort-option label {
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.sort-option select {
    padding: 6px;
    border-radius: 5px;
    background: transparent;
    color: var(--gray-light);
    border: 1px solid var(--gray-light);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.sort-option select:focus {
    outline: none;
    box-shadow: none;
}

.apply-sort-btn {
    width: 100%;
    background: var(--primary-color);
    border: none;
    padding: 8px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-top: 10px;
}

.sort-dropdown-container {
    position: relative;
}

.quest-detail-container {
    background: transparent;
    color: white;
    margin: auto;
    padding: 35px;
}

.quest-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.quest-detail-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.quest-detail-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-location {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: var(--gray-light);
}

.quest-detail-location-icon {
    font-size: 16px;
}

.quest-detail-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.quest-detail-reward-amount {
    font-size: 28px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-reward-label {
    font-size: 13px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.quest-detail-start-button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 6px 20px;
    font-size: 14px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.quest-detail-edit-button {
    background: var(--primary-light-color);
    color: var(--primary-color);
    border: none;
    padding: 6px 28px;
    font-size: 14px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.quest-detail-close-quest {
    background: var(--create-container);
    color: var(--red-color);
    border: none;
    padding: 6px 28px;
    font-size: 14px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.quest-detail-edit-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quest-detail-options-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    padding: 0;
    margin: -10px;
}

.quest-detail-options-dropdown {
    position: absolute;
    background: transparent;
    z-index: 50;
    right: 2%;
    margin-top: 20px;
    border-radius: var(--border-radius);
}

.quest-detail-image-grid {
    display: flex;
    margin-top: 15px;
    gap: 10px;
    height: 50vh;
}

.quest-detail-image-grid.single-image {
    justify-content: center;
}

.quest-detail-image-grid.single-image .quest-detail-side-images {
    display: none;
}

.quest-detail-main-image {
    width: 70%;
    position: relative;
    height: 100%;
}

.quest-detail-main-image.full-width {
    width: 100%;
}

.quest-detail-main-image-img.noimage-full {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    background-color: white;
}

.quest-detail-main-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.quest-detail-image-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
}

.quest-detail-like-button,
.quest-detail-share-button {
    background: var(--neutral-overlay);
    backdrop-filter: blur(50px);
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: var(--border-radius);
}

.quest-detail-side-images {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.quest-detail-side-image-wrapper {
    position: relative;
    height: 50%;
    overflow: hidden;
}

.quest-detail-side-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.quest-detail-extra-images-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(13, 13, 13, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    cursor: pointer;
}

.quest-detail-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.quest-detail-user-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quest-detail-user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.quest-detail-user-detail {
    display: flex;
    flex-direction: column;
}

.quest-detail-user-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-user-username {
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.quest-detail-last-updated {
    font-size: 12px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.quest-detail-insights {
    border-radius: 8px;
    color: #fff;
}

.quest-detail-insights h5 {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-insights-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding-top: 15px;
    position: relative;
}

.quest-insight-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    position: relative;
    cursor: pointer;
}

.quest-insight-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 50px;
    background: var(--gray-light);
}

.quest-insight-label {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.quest-insight-value {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quest-insight-link {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 5px;
}

.quest-insight-link:hover {
    text-decoration: underline;
}

.quest-detail-description-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-description {
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.quest-detail-form {
    background: transparent;
    border-radius: var(--border-radius);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 20%;
}

.quest-detail-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--gray-dark);
    border-radius: var(--border-radius);
    background: transparent;
    color: white;
    font-size: 16px;
}

.quest-detail-textarea::placeholder {
    color: var(--gray-light);
}

.quest-detail-upload-box {
    width: 100%;
    border: 2px dashed var(--gray-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    cursor: pointer;
    padding: 20px 0;
}

.quest-detail-upload-text {
    text-align: center;
    color: var(--text-secondary);
}

.quest-detail-file-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.quest-detail-form-actions {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 15px;
    padding-bottom: 2%;
}

.quest-detail-form-button-cancel {
    background: transparent;
    color: var(--gray-dark);
    padding: 6px 30px;
    border: 1px solid var(--gray-dark);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.quest-detail-form-button-submit {
    background: var(--primary-color);
    color: white;
    padding: 6px 30px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.quest-detail-label {
    font-size: 18px;
    color: var(--text-color);
    margin: 0;
    text-align: start;
    margin-bottom: 10px;
}

.quest-more-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.quest-more-image-carousel-container {
    position: relative;
    width: 60%;
    height: 65vh;
    background: transparent;
    border-radius: var(--border-radius);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quest-more-image-carousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.quest-more-image-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 28px;
    cursor: pointer;
    z-index: 2;
}

.quest-more-image-close-button:hover {
    color: white;
}

.quest-more-image-prev-button,
.quest-more-image-next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    border: none;
}

.quest-more-image-prev-button {
    left: 15px;
}

.quest-more-image-next-button {
    right: 15px;
}

.quest-overlay-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.quest-overlay-image-content {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quest-overlay-image-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.quest-overlay-image-preview-container {
    width: 60%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.quest-overlay-image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: var(--border-radius);
}

.quest-overlay-image-nav {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    border: none;
    cursor: pointer;
}

.quest-overlay-image-nav-left {
    left: 10%;
}

.quest-overlay-image-nav-right {
    right: 10%;
}

.quest-overlay-image-thumbnail-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-top: 3%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    max-width: 90%;
}

.quest-overlay-image-thumbnail-wrapper {
    position: relative;
    cursor: pointer;
    border-radius: var(--border-radius);
    overflow: hidden;
    width: 70px;
    height: 70px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
}

.quest-overlay-image-thumbnail-wrapper:hover {
    transform: scale(1.1);
}

.quest-overlay-image-thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.quest-overlay-image-cover-label {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    width: 70px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    text-align: center;
}

.quest-overlay-image-actions {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.quest-overlay-image-button-cancel {
    background: transparent;
    color: var(--gray-dark);
    padding: 6px 30px;
    border: 1px solid var(--gray-dark);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.quest-overlay-image-button-submit {
    background: var(--primary-color);
    color: white;
    padding: 6px 30px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: var(--border-radius);
}

.quest-close-overlay,
.applicant-accept-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.quest-close-card,
.applicant-accept-card {
    background: var(--gray-background);
    padding: 20px 0 0 0;
    border-radius: var(--border-radius);
    width: 30%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.quest-close-card h4,
.applicant-accept-card h4 {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 20px;
    font-family: var(--font-family-primary);
    text-align: center;
}

.quest-close-list li {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 400;
}

.quest-close-card p,
.applicant-accept-card p {
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 400;
}

.quest-close-buttons,
.applicant-accept-close-buttons {
    display: flex;
    width: 100%;
    border-top: 1px solid var(--gray-background-light);
}

.quest-close-cancel-button,
.quest-close-continue-button,
.quest-close-done-button,
.applicant-accept-button,
.applicant-accept-cancel-button {
    flex: 1;
    text-align: center;
    padding: 15px 0;
}

.quest-close-cancel-button,
.applicant-accept-cancel-button {
    background: transparent;
    color: var(--text-color);
    cursor: pointer;
    color: var(--red-color);
    font-size: 18px;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
    border-right: 1px solid var(--gray-background-light);
}

.quest-close-continue-button {
    background: transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    font-size: 18px;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.quest-close-done-button,
.applicant-accept-button {
    background: transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    font-size: 18px;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.applicant-container {
    background: transparent;
    color: white;
    margin: auto;
    padding: 30px;
}

.applicants-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.applicants-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.applicants-removed-link {
    cursor: pointer;
    color: var(--primary-color);
    font-family: var(--font-family-primary);
    font-weight: 400;
    font-size: 16px;
}

.applicants-search-bar {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.applicants-search {
    width: 100%;
    padding: 10px;
    border-radius: var(--border-radius);
    background: var(--mobile-search-input);
    color: white;
    border: none;
}

.applicants-search::placeholder {
    color: var(--gray-dark);
}

.applicants-search:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

.applicants-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.applicant-card {
    background: transparent;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.applicant-info {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.applicant-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.applicant-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.applicant-message {
    margin: 0;
    font-size: 14px;
    color: var(--gray-light);
    font-family: var(--font-family-primary);
}

.applicant-actions {
    display: flex;
    gap: 10px;
}

.applicant-accept-btn,
.applicant-remove-btn,
.accepted-label {
    border: none;
    padding: 4px 24px;
    color: white;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: 14px;
}

.applicant-accept-btn,
.accepted-label {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.accepted-label {
    cursor: not-allowed;
    background: transparent;
    border: none;
    color: var(--green-text);
    font-size: 16px;
}

.applicant-remove-btn {
    background: transparent;
    border: 1px solid var(--gray-light);
    color: var(--gray-light);
}

.applicants-action-bar {
    padding: 5px 6px;
}

.select-all-checkbox input {
    margin-right: 6px;
    transform: scale(1.1);
    cursor: pointer;
}

.applicants-bulk-actions {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.applicant-checkbox {
    transform: scale(1.1);
    margin-right: 10px;
    cursor: pointer;
}

.applicant-accept {
    color: var(--green-text);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.applicant-remove {
    color: var(--red-color);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.quest-analysis-panel {
    background: transparent;
    padding: 35px 15px;
    border-radius: 8px;
}

.analysis-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.analysis-card {
    background: var(--create-container);
    padding: 15px;
    border-radius: 12px;
    text-align: center;
}

.analysis-card span {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0;
    display: inline-block;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.analysis-card small {
    font-size: 14px;
}

.analysis-card small.trend-up {
    color: #16A34A;
    font-weight: 500;
    font-family: var(--font-family-primary);
    display: flex;
    align-items: center;
    gap: 4px;
}

.analysis-card small.trend-down {
    color: var(--red-color);
    font-weight: 500;
    font-family: var(--font-family-primary);
    display: flex;
    align-items: center;
    gap: 4px;
}

.analysis-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.analysis-card-title .info-icon {
    font-size: 24px;
    color: var(--text-color);
}

.applicant-detail-quest-container {
    background: transparent;
    color: white;
    padding: 35px;
}

.applicant-detail-quest-header {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.applicant-detail-quest-info-card {
    background-color: var(--primary-light-color);
    color: white;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-left: -35px;
    width: 85%;
    box-shadow: 1px 4px 4px 1px rgba(105, 101, 101, 0.25);
}

.applicant-detail-quest-title {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.quest-id {
    color: var(--gray-light);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-family-primary);
}

.submitted-date {
    font-size: 12px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.applicant-detail-quest-submission {
    text-align: right;
}

.amount {
    font-size: 22px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.applicant-detail-quest-carousel-container {
    position: relative;
    margin-top: 30px;
    text-align: center;
}

.applicant-detail-quest-carousel {
    height: 60vh;
    object-fit: contain;
    border-radius: var(--border-radius) !important;
    width: 100%;
}

.applicant-detail-quest-prev-button,
.applicant-detail-quest-next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    border: none;
}

.applicant-detail-quest-next-button {
    right: 10px;
}

.applicant-detail-quest-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.applicant-detail-quest-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.status {
    font-weight: 500;
    font-size: 18px;
    font-family: var(--font-family-primary);
}

.status.accepted {
    color: var(--green-text);
}

.status.removed {
    color: var(--red-color);
}

.applicant-detail-quest-description {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.applicant-detail-quest-description p {
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}