.profile-main-content {
    border-right: 1px solid var(--border-color);
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
}


.profile-header {
    padding: 2% 15%;
    display: flex;
    align-items: center;
    gap: 24px;
}

.profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-info {
    flex: 1;
}

.profile-username {
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    margin-right: 24px;
    color: var(--text-color);
}

.profile-actions {
    gap: 12px;
}

.profile-button {
    padding: 6px 20px;
    font-size: 14px;
    background-color: var(--gray-background);
    color: var(--text-color);
    border-radius: 8px;
    font-weight: 400;
    white-space: nowrap;
}

.profile-button:hover {
    background-color: var(--gray-background);
    color: var(--text-color);
}

.friend-profile-button {
    padding: 6px 30px;
    font-size: 14px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 8px;
    font-weight: 400;
}

.friend-profile-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.friend-profile-share-button {
    padding: 6px 30px;
    font-size: 14px;
    background-color: var(--light-color-button);
    color: var(--gray-light);
    border-radius: 8px;
    font-weight: 400;
}

.friend-profile-share-button:hover {
    background-color: var(--light-color-button);
    color: var(--gray-light);
}

.profile-bottom-section {
    padding: 0% 3%;
}

.profile-stats {
    display: flex;
    gap: 50px;
    font-size: 16px;
    font-family: var(--font-family-primary);
    font-weight: 700;
    color: var(--text-color);
    cursor: pointer;
}

.profile-stats span {
    font-weight: 500;
}

.profile-details {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-top: 10px;
}

.profile-handle {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.profile-category {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
}

.profile-bio {
    font-size: 14px;
    font-family: var(--font-family-primary);
    font-weight: 500;
    color: var(--gray-light);
}

.profile-link {
    font-size: 14px;
    font-family: var(--font-family-primary);
    font-weight: 600;
    color: var(--primary-color);
}

.profile-tabs {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.profile-tab {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: var(--gray-light);
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: var(--font-family-primary);
}

.profile-tab.active {
    color: var(--text-color);
    border-bottom: 3px solid var(--text-color);
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.profile-tab-content {
    margin-top: 10px;
}

.tagged-header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 24px;
}

.tagged-title {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.tagged-count {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.tagged-flicks-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
    padding: 20px 0;
}

.tagged-flick-card {
    flex: 0 0 calc(100% / 6);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
}

.tagged-flick-image {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.tagged-flick-card:hover {
    transform: scale(1.05);
}

.tagged-flick-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #DBEAFE;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #1E40AF;
    font-family: var(--font-family-primary);
}

.tagged-flick-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(50px);
    padding: 5px 7px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tagged-avatar-icon {
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 50%;
    object-fit: cover;
}

.tagged-user-name {
    font-size: 10px;
    font-weight: 600;
    color: white;
    font-family: var(--font-family-primary);
}

.tagged-user-handle {
    font-size: 8px;
    color: #BBBBBE;
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.tagged-quests-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
    padding: 10px 0;
}

.tagged-quest-card {
    flex: 0 0 calc(100% / 2);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.tagged-quest-image {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.tagged-quest-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #F3E8FF;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #6B21A8;
    font-family: var(--font-family-primary);

}

.tagged-quest-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--gray-background);
    padding: 10px;
    text-align: left;
}

.tagged-quest-title {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.tagged-quest-description {
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
    margin: 3px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.tagged-quest-link {
    font-size: 12px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    text-decoration: none;
    color: var(--gray-medium);
}

.tagged-flicks-container,
.tagged-quests-container {
    position: relative;
    display: flex;
    align-items: center;
}

.tagged-flicks-scroll,
.tagged-quests-scroll {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    width: 100%;
}

.tagged-flicks-scroll::-webkit-scrollbar,
.tagged-quests-scroll::-webkit-scrollbar {
    display: none;
}

.scroll-btn {
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(15px);
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 2px 6px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border-radius: 50%;
    color: var(--text-color);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--gray-background);
    padding: 15px;
    border-radius: 16px;
    width: 35%;
    height: 55vh;
    text-align: center;
    position: relative;
}

.modal-content-2 {
    background: var(--gray-background);
    padding: 15px 0 0 0;
    border-radius: 16px;
    width: 33%;
    text-align: center;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 22px;
    color: var(--gray-light);
    cursor: pointer;
}

.follower-heading,
.following-heading {
    font-family: var(--font-family-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: var(--text-color);
    text-align: start;
}

.followers-list,
.following-list {
    flex-grow: 1;
    overflow-y: auto;
}

.follower-search,
.following-search {
    padding: 0 18px;
}

.follower-row,
.following-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;
}

.follower-avatar,
.following-avatar {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.follower-info,
.following-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.follower-name,
.following-name {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    text-align: start;
    font-size: 18px;
    font-weight: 500;
}

.follower-username,
.following-username {
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    text-align: start;
    font-size: 16px;
    font-weight: 400;
}

.remove-btn,
.following-button {
    background: var(--remove-button);
    color: white;
    padding: 4px 26px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-family-primary);
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.following-friend-button {
    background: var(--remove-button);
    color: white;
    padding: 4px 26px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-family-primary);
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.follow-friend-button {
    background: var(--primary-color);
    color: white;
    padding: 4px 26px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-family-primary);
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
}

.confirmation-avatar {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin: 10px 0;
    object-fit: cover;
}

.confirmation-name {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--text-color);
    margin-top: 10px;
}

.confirmation-description {
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-light);
    margin-top: 15px;
    padding-bottom: 28px;
    border-bottom: 1px solid var(--gray-background-light);
}

.confirmation-buttons {
    display: flex;
    width: 100%;
}

.user-profile-remove-button,
.user-profile-cancel-button {
    flex: 1;
    text-align: center;
    padding: 15px 0;
}

.user-profile-cancel-button {
    background: transparent;
    color: var(--text-color);
    border-radius: 6px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
    margin-left: -1px;
}

.user-profile-remove-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #FF3B30;
    border: none;
    font-weight: 500;
    transition: background 0.3s ease-in-out;
    border-right: 1px solid var(--gray-background-light);
    ;
}

/* .user-profile-cancel-button:hover,
.user-profile-remove-button:hover {
    background: transparent;
    border: none;
} */


.follower-row[style*="color: blue"] .remove-btn,
.following-row[style*="color: blue"] .following-button {
    background: var(--primary-color) !important;
    color: white;
}

.follower-row[style*="color: blue"] .remove-btn:hover,
.following-row[style*="color: blue"] .following-button:hover {
    background: var(--primary-color) !important;
}

.share-modal {
    background: var(--gray-background);
    width: 45%;
    padding: 20px;
    height: auto;
    border-radius: 16px;
}

.share-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.share-close-button {
    background-color: transparent;
    border: none;
    color: var(--gray-light);
}

.share-modal-title {
    font-size: 20px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.share-profile-info {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.share-profile-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;

}

.share-profile-name {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}


.share-profile-username {
    font-size: 14px;
    font-weight: 300;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.share-heading {
    font-size: 14px;
    font-family: var(--font-family-primary);
    color: var(--text-color);
    font-weight: 500;
    margin-top: 15px;
}

.share-search-input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid var(--gray-dark);
    background-color: transparent;
    color: var(--text-color);
}

.share-search-input:focus {
    box-shadow: none;
    outline: none;
}

.share-user-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    width: 100%;
    justify-content: space-evenly;
}

.share-user-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: calc(100% / 6 - 14px);
}

.share-user-avatar {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    object-fit: cover;
}

.share-username {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: var(--text-color);
}

.share-social-icons {
    display: flex;
    justify-content: start;
    gap: 15px;
    margin-top: 15px;
}

.social-button {
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
}

.facebook1 {
    background: #1877F2;
}

.linkedin {
    background: #0A66C2;
}

.whatsapp1 {
    background: #25D366;
}

.twitter1 {
    background: #1DA1F2;
}

.mail {
    background: #6B7280;
}

.share-profile-link-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.share-profile-link-container {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--gray-dark);
    border-radius: 8px;
    overflow: hidden;
}

.share-profile-link-container input {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
}

.share-profile-link-container input:focus {
    box-shadow: none;
    outline: none;
}

.share-copy-button {
    padding: 10px 15px;
    background: transparent;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
}

.share-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
}

.share-cancel-btn {
    background: transparent;
    border: 1px solid var(--gray-light);
    color: var(--gray-light);
    padding: 6px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.share-profile-btn {
    background: var(--primary-color);
    border: none;
    color: white;
    padding: 6px 18px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.profile-actions-mobile {
    display: none !important;
}

.user-profile-quest-card {
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.user-profile-quest-image-wrapper {
    position: relative;
}

.user-profile-quest-image {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.user-profile-quest-overlay-top-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-profile-quest-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-profile-quest-user-info {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.user-profile-quest-user-name {
    font-size: 14px;
    color: white;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.user-profile-quest-user-username {
    font-size: 12px;
    color: white;
    opacity: 0.8;
    font-family: var(--font-family-primary);
}

.user-profile-quest-overlay-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.user-profile-quest-status {
    color: #FFF;
    text-shadow: 1px 0.5px 2px rgba(0, 0, 0, 0.25);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user-profile-quest-icon-circle {
    width: 40px;
    height: 40px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
}

.user-profile-quest-icon svg {
    color: white;
    cursor: pointer;
    font-size: 16px;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
}

.user-profile-quest-title-wrapper {
    position: absolute;
    bottom: 27%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
}

.user-profile-quest-title {
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: var(--font-family-primary);
}

.user-profile-quest-go-flick {
    font-size: 16px;
    font-weight: 500;
    color: #34c759;
    filter: drop-shadow(1px 0.5px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
    font-family: var(--font-family-primary);
}

.user-profile-quest-overlay-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30%;
}

.user-profile-quest-description {
    font-size: 14px;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    position: relative;
    color: var(--quest-description-color);
    font-family: var(--font-family-primary);
}

.user-profile-quest-amount {
    font-size: 24px;
    font-weight: bold;
    font-family: var(--font-family-primary);
    color: white;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@media (max-width: 1024px) {
    .profile-main-content {
        width: calc(100% - 70px);
        padding: 5%;
    }

    .profile-header {
        padding: 2% 5%;
    }

    .modal-content {
        width: 65%;
        height: 50vh;
    }

    .modal-content-2 {
        width: 55%;
    }

    .share-modal {
        width: 80%;
    }

    .profile-button {
        white-space: nowrap;
    }
}

@media (max-width: 767px) {


    .profile-main-content {
        margin-bottom: 40px;
        width: calc(100% - 0px);
        padding: 0;
        margin-top: 40px;
        overflow-y: auto;
    }

    .profile-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 2%;
        gap: 5px;
        margin: 0 !important;
    }

    .profile-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .profile-username {
        display: flex;
        font-size: 20px;
        margin: 0 !important;
    }

    .profile-avatar {
        width: 86px;
        height: 86px;
        margin-bottom: 10px;
    }

    .profile-handle {
        display: none;
    }

    .profile-stats {
        display: flex;
        justify-content: center;
        gap: 75px;
        margin-top: 10px;
        margin-bottom: 10px !important;
    }

    .profile-stats span {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }

    .profile-stats strong {
        font-size: 20px;
        font-weight: 500;
    }

    .profile-details {
        text-align: center;
        margin-top: 10px;
    }

    .profile-category,
    .profile-bio,
    .profile-link {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .profile-actions {
        display: none !important;
    }

    .profile-actions-mobile {
        display: flex !important;
        justify-content: center;
        flex-direction: row;
        gap: 12px;
        margin-top: 10px;
        width: 100%;
    }

    .profile-button {
        width: 50%;
        white-space: nowrap;
    }

    .friend-profile-button {
        width: 50%;
        white-space: nowrap;
    }

    .friend-profile-share-button {
        width: 50%;
        white-space: nowrap;
    }

    .friend-profile-button:hover {
        background-color: var(--primary-color);
        color: white;
    }

    .profile-button:hover {
        background-color: var(--background-color);
        color: var(--text-color);
    }


    .profile-bottom-section {
        margin-bottom: 90px;
    }

    .tagged-quest-card {
        flex: 0 0 calc(100% / 1);
    }

    .tagged-flick-card {
        flex: 0 0 calc(100% / 3);
    }

    .modal-content {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        margin-top: 20%;
        padding: 15px;
    }

    .follower-row {
        padding: 6px 0;
    }

    .follower-search,
    .following-search {
        padding: 0;
    }

    .modal-content-2 {
        width: 80%;
    }

    .share-modal {
        width: 99%;
        height: auto;
        padding: 8px;
        border-radius: 0;
    }

    .share-user-avatar {
        height: 52px;
        width: 52px;
    }

    .share-user-list {
        gap: 18px;
    }

    .share-username {
        font-size: 12px;
        white-space: nowrap;
    }

    .social-button {
        padding: 0;
    }
}