.create-main-content {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.create-tab-bar {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 0 0 0;
}

.create-tab-button {
    background: none;
    border: none;
    color: var(--gray-light);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.create-tab-button.active {
    color: var(--text-color);
    border-bottom: 2px solid var(--text-color);
}

.create-tab-button:hover {
    color: var(--text-color);
}

.upload-container {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--create-container);
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.upload-outer-container {
    padding: 5% 8%;
}

.upload-icon-circle {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: var(--gray-background);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.upload-icon {
    font-size: 70px;
    color: var(--primary-color);
}

.upload-text {
    font-size: 18px;
    margin: 0 0 10px 0;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-weight: 500;
}

.upload-subtext {
    font-size: 18px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
    font-weight: 500;
    margin-bottom: 20px;
}

.select-files-button {
    background-color: var(--light-color-button);
    border: none;
    color: var(--text-color);
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
}

.select-files-button:hover {
    background-color: var(--light-color-button);
    color: var(--text-color);
}

.go-next-button {
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 8px 30px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-family: var(--font-family-primary);
}

.go-next-button:hover {
    background-color: var(--primary-color);
}

.story-options-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
    padding: 20px;
}

.top-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    padding: 10px 20px;
    border-bottom: 1px solid var(--border-color);
}

.undo-redo-buttons {
    display: flex;
    gap: 10px;
    margin-left: 40%;
}

.undo-button,
.redo-button {
    background-color: transparent;
    color: var(--gray-dark);
    border: none;
    font-size: 24px;
    cursor: not-allowed;
    transition: background 0.3s, color 0.3s;
}

.undo-button:enabled,
.redo-button:enabled {
    background: transparent;
    color: var(--text-color);
    cursor: pointer;
}

.cancel-finalize-buttons {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    margin-left: auto;
}

.cancel-finalize-buttons .close-button {
    background: transparent;
    border: none;
    color: var(--gray-dark);
    font-size: 24px;
    padding: 0 0 0 15px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.cancel-finalize-buttons .finalize-button {
    background: var(--primary-color);
    border: none;
    color: white;
    font-size: 16px;
    padding: 6px 26px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.reset-button {
    background: transparent;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    font-size: 16px;
    padding: 6px 26px;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    /* margin-left: auto;
    margin-right: -10%; */
}

.story-tools {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
    padding: 25px 25px 25px 0;
    border-right: 1px solid var(--border-color);
    cursor: pointer;
}

.tool-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--gray-dark);
    height: 45px !important;
    width: 45px !important;
}

.tool-button span {
    font-size: 40px;
}

.crop-tool-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--gray-dark);
    /* height: 40px !important;
    width: 40px !important; */
}

.crop-tool-button span {
    font-size: 36px;
}

.tool-button svg {
    color: var(--gray-dark);
    transition: color 0.3s ease-in-out;
}

.tool-button.active svg {
    color: var(--text-color);
}

.capsule-button {
    position: absolute;
    top: 12%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(50px);
    color: white;
    border-radius: 50px;
    z-index: 3;
    width: 14%;
    cursor: pointer;
}

.avatar {
    width: 35px;
    height: 35px;
    background: #D9D9D9;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow: hidden;
}

.avatar .icon {
    font-size: 10px !important;
    padding: 5px;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.sound-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.sound-info p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
}

.sound-info span span {
    font-size: 10px;
    opacity: 0.8;
}

@keyframes pulse {
    0% {
        height: 22px;
    }

    50% {
        height: 14px;
    }

    100% {
        height: 22px;
    }
}

@keyframes reversePulse {
    0% {
        height: 14px;
    }

    50% {
        height: 22px;
    }

    100% {
        height: 14px;
    }
}

.equalizer {
    display: flex;
    gap: 4px;
    width: max-content;
    height: 8px;
    align-items: center;
    padding-right: 3px;
}

.equalizer span {
    width: 3px;
    background-color: white;
    border-radius: 4px;
}

.left {
    height: 18px;
}

.middle {
    height: 10px;
}

.right {
    height: 18px;
}

.playing .left {
    height: 18px;
    animation: pulse 1.5s infinite;
}

.playing .middle {
    height: 10px;
    animation: reversePulse 1.5s infinite;
}

.playing .right {
    height: 18px;
    animation: pulse 1.5s infinite;
}

.scrolling-text {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 95px;
}

.playing .scrolling-text span {
    display: inline-block;
    animation: marquee 7s linear infinite;
    min-width: 100%;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.image-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.image-preview-container {
    width: 32%;
    height: 80vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;
    box-shadow: var(--image-container-shadow);
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: var(--border-radius);
}

.bottom-buttons {
    position: absolute;
    bottom: 2%;
    display: flex;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.right-buttons {
    display: flex;
    gap: 20px;
}

.edit-button {
    background-color: var(--edit-button);
    border: 1px solid var(--edit-button);
    color: white;
    font-size: 16px;
    padding: 6px 36px;
    border-radius: var(--border-radius);
    font-family: var(--font-family-primary);
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.download-button {
    background-color: transparent;
    border: 1px solid var(--gray-dark);
    color: var(--gray-dark);
    font-size: 16px;
    padding: 6px 26px;
    border-radius: var(--border-radius);
    font-family: var(--font-family-primary);
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.post-story-button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    font-size: 16px;
    padding: 6px 26px;
    border-radius: var(--border-radius);
    font-family: var(--font-family-primary);
    font-weight: 400;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.popup-box {
    background: var(--gray-background);
    padding: 25px 0 0 0;
    border-radius: 16px;
    width: 27%;
    text-align: center;
    position: relative;
    height: auto;
}

.popup-heading {
    color: var(--gray-light);
    font-family: var(--font-family-primary);
    font-size: 18px;
    font-weight: 400;
    padding: 10px 10px 30px 10px;
    border-bottom: 1px solid var(--gray-dark);
}

.confirmation-buttons {
    display: flex;
    width: 100%;
}

.confirmation-buttons .remove-button,
.confirmation-buttons .cancel-box-button {
    flex: 1;
    text-align: center;
    padding: 15px 0;
}

.confirmation-buttons .cancel-box-button {
    background: transparent;
    color: var(--text-color);
    border-radius: 6px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
    margin-left: -1px;
}

.confirmation-buttons .remove-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #FF3B30;
    border: none;
    font-weight: 400;
    transition: background 0.3s ease-in-out;
    border-right: 1px solid var(--gray-dark);
}

.confirmation-buttons .cancel-box-button:hover,
.confirmation-buttons .remove-button:hover {
    background: transparent;
}

.right-side-tools {
    position: absolute;
    display: flex !important;
    right: 28%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 3vh;
    align-items: center;
    padding: 25px 0 25px 25px;
}

.right-side-tools.active {
    background-color: var(--primary-color);
    color: white;
}

.filter-options {
    position: absolute;
    display: flex !important;
    right: 20%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 3vh;
    align-items: center;
    padding: 25px 0 25px 25px;
}

.aspect-ratio-options {
    position: absolute;
    right: 15%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background: transparent;
    padding: 8px;
    border-radius: 5px;
    z-index: 10;
}

.aspect-ratio-options button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid var(--gray-light);
    background: transparent;
    cursor: pointer;
    height: 80px;
    width: 80px;
    border-radius: var(--border-radius);
    color: var(--gray-light);
    gap: 5px;
}

.aspect-box {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 1px solid var(--gray-light);
    border-radius: var(--border-radius);
}

.square {
    width: 40px;
    height: 40px;
}

.rectangle {
    width: 55px;
    height: 40px;
}

.portrait {
    width: 30px;
    height: 45px;
}

button span {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: var(--gray-light);
}

.done-button {
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--font-family-primary);
}

.done-button:hover {
    color: var(--text-color);
}

.color-picker-container {
    position: absolute;
    right: 20%;
    top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.color-dropper {
    width: 28px;
    height: 28px;
    background: var(--neutral-overlay);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.color-dropper:hover {
    transform: scale(1.1);
}

.hue-picker {
    height: 60vh !important;
    width: 12px !important;
    border-radius: var(--border-radius) !important;
    border: 1.7px solid #FFF;
}

.hue-vertical {
    border-radius: var(--border-radius) !important;
}

.hue-vertical div:nth-child(2) {
    background-color: transparent !important;
}

.color-ball {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-left: 1px;
    background:
        radial-gradient(50% 50% at 50% 50%, #E4E4E4 0%, rgba(228, 228, 228, 0.00) 88%),
        conic-gradient(from 0deg at 50% 50%,
            #E7E040 0deg,
            #EEAA3C 44.65deg,
            #E8403B 89.78deg,
            #B33ED5 131.61deg,
            #694AE8 182.19deg,
            #3CCAE7 231.34deg,
            #3CE885 269.54deg,
            #89E743 313.04deg,
            #E7E040 360deg);
}

.color-ball:hover {
    transform: scale(1.1);
}

.full-color-picker {
    position: absolute;
    bottom: 10px;
    left: 30px;
    background: white;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 10;
}

.filters-container {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-height: 65vh;
    overflow-y: auto;
    background: var(--gray-background);
    padding: 10px;
    border-radius: var(--border-radius);
}

.filters-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.filter-item {
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.filter-preview {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
}

.filter-item span {
    font-size: 12px;
    color: white;
    margin-top: 5px;
    text-align: center;
    word-wrap: nowrap;
    font-weight: 300;
    font-family: var(--font-family-primary);
}

.filter-item.active {
    border: 2px solid white;
    border-radius: 5px;
}

.no-effect {
    filter: none;
}

.filter-vintage {
    filter: sepia(50%) contrast(85%) brightness(90%);
}

.filter-warm {
    filter: sepia(30%) brightness(105%) contrast(95%);
}

.filter-cool {
    filter: hue-rotate(180deg) brightness(90%) contrast(110%);
}

.filter-cinematic {
    filter: contrast(120%) saturate(80%) brightness(90%);
}

.filter-bw {
    filter: grayscale(100%);
}

.filter-brighten {
    filter: brightness(120%);
}

.filter-darken {
    filter: brightness(70%);
}

.filter-soft-glow {
    filter: brightness(110%) contrast(90%) blur(2px);
}

.filter-deep-contrast {
    filter: contrast(150%) brightness(90%);
}

.filter-pastel {
    filter: saturate(80%) brightness(110%) contrast(90%);
}

.filter-matte {
    filter: brightness(95%) contrast(85%);
}

.filter-fade {
    filter: brightness(105%) contrast(85%) saturate(80%);
}

.filter-ocean-blue {
    filter: hue-rotate(200deg) contrast(110%) brightness(90%);
}

.filter-retro {
    filter: sepia(60%) hue-rotate(20deg) contrast(90%);
}

.filter-golden-hour {
    filter: sepia(40%) brightness(105%) contrast(95%);
}

.filter-lush-green {
    filter: hue-rotate(90deg) saturate(120%) brightness(90%);
}

.filter-purple-haze {
    filter: hue-rotate(270deg) brightness(110%) contrast(90%);
}

.filter-sunset-glow {
    filter: sepia(30%) contrast(110%) brightness(105%);
}

.filter-drama {
    filter: contrast(140%) brightness(90%) saturate(70%);
}

.filter-moody {
    filter: contrast(130%) brightness(80%) saturate(90%);
}

.filter-cyberpunk {
    filter: hue-rotate(280deg) contrast(120%) brightness(110%);
}

.filter-neon-lights {
    filter: hue-rotate(300deg) saturate(150%) brightness(120%);
}

.filter-sepia {
    filter: sepia(100%);
}

.filter-dreamy {
    filter: brightness(110%) blur(3px) saturate(80%);
}

.filter-glow-up {
    filter: brightness(120%) contrast(110%) saturate(120%);
}

.filter-frosted {
    filter: blur(5px) brightness(110%) contrast(90%);
}

.filter-dusky {
    filter: brightness(80%) contrast(120%) sepia(30%);
}

.filter-holographic {
    filter: hue-rotate(180deg) brightness(110%) contrast(90%);
}

.filter-muted {
    filter: brightness(90%) contrast(80%) saturate(60%);
}

.filter-midnight {
    filter: brightness(70%) contrast(120%) hue-rotate(240deg);
}

.filter-vivid {
    filter: contrast(130%) brightness(110%) saturate(140%);
}

.filter-forest {
    filter: hue-rotate(100deg) brightness(90%) contrast(110%);
}

.filter-skyline {
    filter: brightness(110%) contrast(90%) hue-rotate(220deg);
}

.filter-faded-pink {
    filter: hue-rotate(320deg) brightness(100%) contrast(90%);
}

.filter-cloudy {
    filter: brightness(90%) contrast(80%) saturate(90%);
}

.filter-electric {
    filter: hue-rotate(260deg) contrast(130%) brightness(120%);
}

.filter-autumn {
    filter: sepia(50%) brightness(90%) contrast(110%);
}

.filter-parisian {
    filter: brightness(1.1) contrast(0.9) saturate(1.2);
}

.filter-los-angeles {
    filter: sepia(0.3) brightness(1.2) contrast(1.1);
}

.filter-jaipur {
    filter: sepia(0.5) saturate(1.5) brightness(1.1);
}

.filter-new-york {
    filter: contrast(1.4) brightness(0.9) saturate(0.8);
}

.filter-tokyo-nights {
    filter: hue-rotate(220deg) contrast(1.2) brightness(1.1);
}

.filter-bali {
    filter: contrast(1.3) brightness(1.1) saturate(1.5);
}

.filter-santorini {
    filter: contrast(1.2) brightness(1.1) saturate(1.4);
}

.filter-marrakech {
    filter: sepia(0.6) contrast(1.2) brightness(1.1);
}

.filter-venice {
    filter: brightness(1.2) contrast(0.9) saturate(1.1);
}

.filter-sydney {
    filter: brightness(1.3) contrast(1.1) saturate(1.3);
}

.filter-inkwell {
    filter: grayscale(1) contrast(1.2);
}

.filter-lark {
    filter: brightness(1.2) contrast(0.9) saturate(1.1);
}

.filter-gingham {
    filter: brightness(1.1) contrast(0.85);
}

.filter-hudson {
    filter: contrast(1.2) brightness(1.1) saturate(1.3);
}

.filter-amaro {
    filter: contrast(1.2) brightness(1.2) sepia(0.3);
}

.filter-reyes {
    filter: brightness(1.2) contrast(0.85) sepia(0.2);
}

.filter-aden {
    filter: contrast(1.1) brightness(1.15) saturate(1.2);
}

.filter-perpetua {
    filter: contrast(1.1) brightness(1.15) saturate(1.4);
}

.filter-slumber {
    filter: brightness(1.1) contrast(0.85) sepia(0.3);
}

.filter-xpro-ii {
    filter: contrast(1.4) brightness(0.9) sepia(0.5);
}

.filter-ethereal {
    filter: brightness(1.3) contrast(0.9) blur(2px);
}

.filter-noir {
    filter: grayscale(1) contrast(1.5);
}

.filter-sun-kissed {
    filter: brightness(1.3) contrast(1.1) saturate(1.2);
}

.filter-dusty-film {
    filter: sepia(0.4) contrast(1.1) brightness(0.9);
}

.filter-velvet {
    filter: contrast(1.3) brightness(1.2) saturate(1.4);
}

.filter-glass {
    filter: contrast(1.2) brightness(1.1) blur(1px);
}

.filter-pastel-pop {
    filter: brightness(1.1) contrast(0.9) saturate(1.3);
}

.filter-sunflare {
    filter: brightness(1.4) contrast(1.1) sepia(0.2);
}

.filter-glow-bloom {
    filter: brightness(1.2) contrast(0.8) blur(3px);
}

.filter-frostbite {
    filter: brightness(0.9) contrast(1.2) hue-rotate(180deg);
}

.filter-heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.no-effect-box {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-dark);
    border-radius: 8px;
    background-color: transparent;
}

.no-effect-icon {
    opacity: 0.6;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.brush-types {
    position: absolute;
    right: 29%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 45px;
    color: var(--gray-dark);
    cursor: pointer;
}

.brush-types .selected {
    color: var(--text-color);
}

.size-slider {
    position: absolute;
    top: 20%;
    right: 23%;
    width: 30px;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-track {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--neutral-overlay);
    clip-path: polygon(50% 100%, 100% 0%, 0% 0%);
    z-index: 0;
}

.slider-bar {
    position: absolute;
    width: 0px;
    height: 100%;
    background: var(--neutral-overlay);
    border-radius: 10px;
    z-index: 1;
}

.size-slider input {
    -webkit-appearance: none;
    appearance: none;
    width: 50vh;
    height: 8px;
    position: absolute;
    background: none;
    opacity: 1;
    cursor: pointer;
    z-index: 2;
    transform: rotate(-90deg);
}

.size-slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 38px;
    height: 38px;
    background: var(--gray-dark);
    border-radius: 50%;
    border: none;
    position: relative;
    z-index: 3;
    cursor: grab;
}

.color-picker {
    position: absolute;
    right: 18%;
    top: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-picker input {
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
}

.no-scroll {
    touch-action: none;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.text-options-container {
    position: absolute;
    right: 12%;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-height: 65vh;
    overflow-y: auto;
    background: var(--gray-background);
    padding: 10px 25px 10px 15px;
    border-radius: var(--border-radius);
}

.text-style-heading {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
}

.text-option {
    border: 1px solid var(--gray-dark);
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    color: var(--text-color) !important;
}

.text-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: move;
    padding: 5px;
    max-width: 250px;
    min-height: 30px;
    word-wrap: break-word;
}

.text-content {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    user-select: none;
    word-wrap: break-word;
    padding: 5px;
}

.resize-handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;
}

.rotate-handle {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotate-icon {
    font-size: 20px;
    color: white;
    background-color: transparent;
}

.delete-text {
    position: absolute;
    top: -10px;
    left: -10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.color-picker-section {
    position: absolute;
    right: 9%;
    top: 20%;
}

.sticker-picker {
    position: absolute;
    right: 8%;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-height: 65vh;
    overflow-y: auto;
    background: var(--gray-background);
    padding: 10px 25px 10px 15px;
    border-radius: var(--border-radius);
}

.giphy-gif-img {
    background: none !important;
}

.giphy-gif-img:hover {
    background: none !important;
}

.giphy-attribution {
    display: none !important;
    background: none !important;
}

.cbPHsF {
    background: none !important;
}

.sticker-search {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: var(--border-radius);
    background-color: transparent;
    border: 1px solid var(--gray-dark);
    color: var(--text-color);
}

.sticker-search:focus {
    box-shadow: none;
    border: 1px solid var(--gray-dark);
    outline: none;
}

.sticker-search::placeholder {
    color: var(--gray-light);
}

.sticker-heading {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
}

.sticker-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: move;
    padding: 5px;
    max-width: 250px;
    min-height: 30px;
    word-wrap: break-word;
}

.text-content {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    user-select: none;
    word-wrap: break-word;
    padding: 5px;
}

.added-sticker {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    user-select: none;
    word-wrap: break-word;
    padding: 5px;
}

.sticker-box.selected {
    outline: 1px solid white;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.delete-sticker {
    position: absolute;
    top: -12px;
    left: -12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}

.sticker-resize-handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;
}

.sticker-rotate-handle {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotate-icon {
    font-size: 20px;
    color: white;
    background-color: transparent;
}

.story-music {
    position: absolute;
    right: 8%;
    top: 50%;
    transform: translateY(-50%);
    width: 25%;
    height: 65vh;
    overflow-y: auto;
    background: var(--gray-background);
    padding: 10px 5px 10px 5px;
    border-radius: var(--border-radius);
}

.music-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 490px;
}

.song-item {
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 8px;
    padding: 2px 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.song-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.song-image {
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius);
}

.selected-song-image {
    width: 100px;
    height: 100px;
    border-radius: var(--border-radius);
}

.song-title {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--primary-color);
    color: var(--text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.song-artist {
    font-size: 12px;
    color: #CFD0D2;
    font-family: var(--primary-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.play-pause-button {
    background-color: transparent;
    border: none;
    color: var(--text-color);
}

.song-header-container {
    width: 100%;
}

.song-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.add-music-button {
    font-size: 20px;
    color: var(--text-color);
    border: none;
    background: none;
    cursor: pointer;
}

.song-search {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: var(--border-radius);
    background-color: transparent;
    border: 1px solid var(--gray-dark);
    color: var(--text-color);
}

.song-search:focus {
    box-shadow: none;
    border: 1px solid var(--gray-dark);
    outline: none;
}

.song-search::placeholder {
    color: var(--gray-light);
}

.song-heading {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 0 5px;
}

.song-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background: transparent;
    border-radius: 10px;
}

.waveform-container {
    width: 100%;
    height: 50px;
}

.song-bar-controls {
    display: flex;
    justify-content: space-between;
    width: auto;
    gap: 5px;
}

.cancel-audio-button {
    background: transparent;
    color: var(--text-color);
    border: 2px solid var(--text-color);
    padding: 4px 26px;
    font-size: 14px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background 0.3s;
    font-family: var(--primary-color);
}

.use-audio-button {
    background: var(--text-color);
    color: var(--opposite-text-color);
    border: 1px solid var(--text-color);
    padding: 4px 26px;
    font-size: 14px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background 0.3s;
    font-family: var(--primary-color);
}

.cancel-audio-button:hover {
    background: transparent;
    color: var(--text-color);
    border: 2px solid var(--text-color);
}

.full-song-progress-container {
    position: relative;
    width: 100%;
    height: 20px;
    cursor: pointer;
    margin-top: 25px;
}

.time-labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--text-color);
    margin-top: 5px;
}

.full-song-progress-bar {
    position: relative;
    width: 100%;
    height: 6px;
    background: var(--gray-dark);
    border-radius: 3px;
    overflow: hidden;
}

.selected-region {
    position: absolute;
    height: 100%;
    background: var(--text-color);
    border-radius: 3px;
}

.playback-indicator {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: rgba(255, 0, 0, 0.7);
    border-radius: 50%;
}

.delete-song-button {
    position: absolute;
    right: -25%;
    padding: 5px 10px;
    background: rgba(82, 82, 84, 0.30);
    backdrop-filter: blur(50px);
    border: none;
    border-radius: 50%;
    color: var(--red-color);
    font-size: 24px;
    cursor: pointer;
}

.story-setting-container {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 33%;
    height: 80vh;
    overflow-y: auto;
    background: transparent;
    padding: 0 10px;
}

.story-setting-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.story-setting-section {
    margin-bottom: 16px;
}

.story-setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--gray-dark);
    cursor: pointer;
}

.story-setting-label {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 14px;
}

.story-setting-value {
    display: flex;
    align-items: center;
}

.story-setting-number {
    color: var(--text-color);
    margin-right: 8px;
    font-size: 14px;
}

.story-setting-icon {
    color: var(--gray-dark);
}

.story-setting-description {
    font-size: 12px;
    color: var(--gray-dark);
    margin-top: 4px;
    font-family: var(--font-family-primary);
}

.story-setting-border-top {
    border-top: 1px solid #333;
    padding-top: 16px;
}

.story-setting-subheader {
    font-size: 16px;
    color: var(--gray-dark);
    font-family: var(--font-family-primary);
    display: block;
    margin-bottom: 16px;
}

.story-setting-options {
    display: flex;
    gap: 16px;
    margin-top: 8px;
    font-size: 14px;
    flex-direction: column;
}

.story-setting-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
}

.story-setting-option input[type="radio"] {
    margin-right: 6px;
    accent-color: var(--primary-color);
    order: 1;
    height: 15px;
    width: 15px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.overlay-content {
    background: var(--gray-background);
    width: 85%;
    border-radius: var(--border-radius);
    padding: 16px;
    color: var(--text-color);
    text-align: center;
}

.overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--gray-dark);
}

.overlay-heading {
    color: var(--text-color);
    font-family: var(--font-family-primary);
    font-size: 20px;
}

.close-icon {
    cursor: pointer;
    font-size: 20px;
}

.setting-search-bar {
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 8px;
    margin-bottom: 15px;
    background: var(--mobile-search-input);
    color: var(--text-color);
}

.setting-search-bar:focus {
    box-shadow: none;
    outline: none;
}

.friends-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    max-height: 40vh;
    overflow-y: auto;
    padding-right: 8px;
}

.friend-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.setting-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 6px;
}

.setting-username {
    font-size: 13px;
    color: var(--text-color);
    font-family: var(--font-family-primary);
    white-space: nowrap;
}

.close-friend-btn {
    width: 100%;
    padding: 10px;
    background: var(--primary-color);
    border: none;
    border-radius: var(--border-radius);
    color: white;
    font-weight: 400;
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
    font-family: var(--font-family-primary);
}

.avatar-wrapper {
    position: relative;
    display: inline-block;
}

.tick-mark {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--primary-color);
    color: white;
    font-size: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

/* Flick part CSS */
.images-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(82, 82, 84, 0.3);
    backdrop-filter: blur(50px);
    border: none;
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50%;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.prev-btn:hover,
.next-btn:hover {
    background-color: rgba(82, 82, 84, 0.3);
}

.carousel-dots {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: white;
    width: 12px;
    height: 12px;
}

.video-preview::-webkit-media-controls-fullscreen-button {
    display: none !important;
}

.video-preview::-webkit-media-controls {
    overflow: hidden !important;
}

/* .video-preview::-webkit-media-controls-volume-slider {
    display: none !important;
} */

/* Flick part CSS */


@media (max-width: 1024px) {
    .image-preview-container {
        width: 66%;
        height: 70vh;
        margin-left: 0;
        margin-top: -20%;
    }

    .right-side-tools {
        right: 5%;
    }

    .aspect-ratio-options {
        position: relative;
        top: -4%;
        flex-direction: row;
        right: 0;
    }
}